import { Field, FormikProvider, useFormik } from 'formik';
import { ReactNode, useEffect, useRef, useState } from "react";
import * as Yup from 'yup';


import classnames from "classnames/bind";
import QRCodeStyling from "qr-code-styling";

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/scss'; // core Swiper
import 'swiper/scss/pagination'; // Pagination module

import { Flex, FlexContainer, Icon, Input, ModalSheet, Panel } from "../../components";
import { Button } from "../../components/button_";
import { address } from "../../interfaces";
import { WidgetColors } from '../../types';
import styles from './Form.module.scss';
import stylesSend from './SendForm.module.scss';

const cnb = classnames.bind(styles);
const cnbsend = classnames.bind(stylesSend);

interface FormValues {
  amount: number | undefined;
  wallet: address;
}

const Loader = () => (
  <svg className={styles.spinner}>
    <circle cx="20" cy="20" r="12"></circle>
  </svg>
);

const SlideWrapper = ({ direction, disabled = false, children }: { direction: "next" | "prev" | number, disabled?: boolean, children: ReactNode }) => {
  const swiper = useSwiper();
  return (
    <div className={cnb("swiper-button-wrapper")} onClick={() => !disabled && (direction === "prev" ? swiper.slidePrev() : (direction === "next" ? swiper.slideNext() : swiper.slideTo(direction)))}>{children}</div>
  );
}

type SendFormProps = {
  widgetColors?: WidgetColors;
}

export const SendForm = ({ widgetColors }: SendFormProps) => {
  const swiper = useSwiper();

  const [confirmed, setConfirmed] = useState<boolean>(false);
  const refQr = useRef<HTMLDivElement>(null);

  const done = () => {
    formik.resetForm();
  }

  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    type: "svg",
    margin: 0,
    qrOptions: {
      typeNumber: 0,
      mode: "Byte",
      errorCorrectionLevel: "L"
    },
    imageOptions: {
      hideBackgroundDots: false,
      imageSize: 0,
      margin: 0
    },
    dotsOptions: {
      type: "rounded",
      color: widgetColors?.backP ? `#${widgetColors.backP}` : "#1C2124"
    },
    backgroundOptions: {
      color: "#ffffff"
    },
    image: "",
    cornersSquareOptions: {
      type: undefined,
      color: widgetColors?.backP ? `#${widgetColors.backP}` : "#1C2124"
    },
    cornersDotOptions: {
      type: undefined,
      color: widgetColors?.backP ? `#${widgetColors.backP}` : "#1C2124"
    }
  });

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Nice try😄')
      .required('')
      .positive('Nice try😄')
      .min(0.000000001, 'Minimum 0.000000001')
      .max(1000000, 'Maximum 1000000'),
    wallet: Yup.string()
      .required('')
      .matches(/^0:[a-f0-9]{64,64}$/, "Doesn't look like a valid wallet address")
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      amount: undefined,
      wallet: ''
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: ({ amount, wallet }, { setSubmitting }) => {
      swiper.slideNext();
    }
  });

  const [url, setUrl] = useState<string>();

  useEffect(() => {
    if (refQr.current && formik.values.wallet && formik.values.amount) {
      qrCode.update({
        data: `https://uri.ever.surf/send/${formik.values.wallet}?amount=${formik.values.amount}&token=EVER&net=mainnet`
      });
      setUrl(`https://uri.ever.surf/send/${formik.values.wallet}?amount=${formik.values.amount}&token=EVER&net=mainnet`);
      refQr.current.innerHTML = '';
      qrCode.append(refQr.current);
      if (refQr.current.querySelector("svg")) refQr.current.querySelector("svg")!.setAttribute("viewBox", "0 0 300 300");
    }
  }, [url, confirmed, formik.values]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={cnb({ "active": false }, "form")}>
        <Swiper
          className={cnbsend("send-swiper")}
          spaceBetween={0}
          slidesPerView={1}
          allowTouchMove={false}
          navigation={false}
          pagination={false}
          scrollbar={false}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          <SwiperSlide>
            <Panel
              className={"widget-bg-primary"}
              header={<h5 className={"title title-normal widget-panel-title widget-text-primary"}>Send tokens</h5>}
            >
              <Field
                name={"wallet"}
                as={Input}
                type={"text"}
                label={"Recipient’s address"}
                placeholder={""}
                composition={"slide"}
                caption={""}
                validation={formik.errors["wallet"]}
                className={"widget-input surf-input"}
              />
              <Field
                name={"amount"}
                as={Input}
                type={"number"}
                label={"Amount"}
                placeholder={""}
                composition={"slide"}
                caption={""}
                validation={formik.errors["amount"]}
                className={"widget-input surf-input"}
              />
              <SlideWrapper direction="next" disabled={!formik.dirty || formik.isSubmitting || !formik.isValid}>
                <Button
                  size="lg"
                  variant="primary"
                  type="button"
                  className={`${cnb("button-action")} widget-button`}
                  disabled={!formik.dirty || formik.isSubmitting || !formik.isValid}
                >
                  {formik.isSubmitting && <Loader />} Preview transaction
                </Button>
              </SlideWrapper>
            </Panel>
          </SwiperSlide>
          <SwiperSlide>
            <Panel
              className={`${cnbsend("slider-slide-panel")} widget-bg-primary`}
              header={<h5 className={"title title-normal widget-text-primary"}>
                <SlideWrapper direction="prev">
                  <Icon icon="arrow-left" className={`${cnb("icon-back")} widget-icons-text`} />Back
                </SlideWrapper>
              </h5>}
            >
              <dl className={cnb("send-summary", "paragraph", "paragraph-normal")}>
                <FlexContainer
                  className={`${cnbsend("send-summary-column")} widget-summary-row`}
                  justify="space-between"
                  direction="column"
                >
                  <dt className={`${cnb("color-faded", "paragraph", "paragraph-small")} widget-text-secondary`}>Address</dt>
                  <dd>{formik.values.wallet}</dd>
                </FlexContainer>
                <FlexContainer
                  className={`${cnb("send-summary-row")} widget-summary-row`}
                  justify="space-between"
                >
                  <dt className={`${cnb("color-faded", "paragraph")} widget-text-secondary`}>Amount</dt><dd>{formik.values.amount && (+formik.values.amount).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} EVER</dd>
                </FlexContainer>
                <FlexContainer
                  className={`${cnb("send-summary-row")} widget-summary-row`}
                  justify="space-between"
                >
                  <dt className={`${cnb("color-faded", "paragraph")} widget-text-secondary`}>Fee</dt><dd>~ 0.01 EVER</dd>
                </FlexContainer>
              </dl>

              <Button
                size="lg"
                variant="primary"
                type="button"
                className={`${cnb("button-action")} widget-button`}
                disabled={formik.isSubmitting}
                onClick={() => {
                  setConfirmed(true);
                }}
              >
                {formik.isSubmitting && <Loader />} Proceed to send
              </Button>
            </Panel>

            <ModalSheet
              show={confirmed}
              className={`${cnbsend("send-confirmed")} widget-bg-primary`}
              header={<FlexContainer
                justify="space-between"
                align="center"
                style={{ flexGrow: 1 }}
              >
                <Flex className={"title title-normal widget-text-primary"}>Confirm action</Flex>
                <Flex>
                  <Button
                    size="blank"
                    variant="variant"
                    onClick={() => {

                      console.log('close click');
                      setConfirmed(false);
                    }}
                    className={"widget-text-primary"}
                  >
                    <Icon className={"widget-icons-text"} icon={"close"} />
                  </Button>
                </Flex>
              </FlexContainer>}
            >
              <div ref={refQr} className={`${cnb("qrcode")} ${cnbsend("qrcode")}`}></div>

              <div className={`${cnb("qrcode-desc", "paragraph", "paragraph-small", "align-center")} widget-text-secondary`}>{`Scan this QR code, follow the link\n and confirm the send in the app`}</div>

              <FlexContainer
                justify="space-between"
                align="stretch"
                direction="column"
              >
                <Flex grow={1} className={cnbsend("confirm-desc")}>
                  <a href={url} target="_blank" rel="noreferrer">
                    <Button size="lg" color='primary' className={`${cnbsend("confirm-btn")} widget-button`}>
                      Confirm with Everlink
                    </Button>
                  </a>
                </Flex>
                <Flex grow={1}>
                  <SlideWrapper direction={0}>
                    <Button size="lg" type="reset" className={`${cnbsend("done-btn")} widget-button`} onClick={done}>Done</Button>
                  </SlideWrapper>
                </Flex>
              </FlexContainer>
            </ModalSheet>
          </SwiperSlide>
        </Swiper>
      </form>
    </FormikProvider >
  );
};

export default SendForm;