import { Dispatch, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionTypes, RootState } from "../types";
import { store } from "../redux/store/configure-store";

import DebotClient from "../client/client";

import {
  loadTokens,
  setTokens,
  loadPairs,
  setPairs,
  loadRate,
  setRate
} from "../redux/actions";

import {
  Token,
  Rate
 } from "../interfaces";
import { ReactNode } from "react-markdown";
import { PromiseState } from "../utils/store/PromiseUtils";

export interface IUseSwap {
    tokens: Token[],
    pairs: Token[],
    rate: Rate | undefined,
    rateState: PromiseState,
    left: Token | undefined,
    right: Token | undefined,
    setLeft: (token: Token) => void,
    setRight: (token: Token) => void,
    setLeftAmount: (amount: number) => void,
}

export const useSwap = (): IUseSwap => {
  const dispatch = useDispatch();

  const { 
    tokens: {data: tokens},
    rate: {data: rate, promiseState: rateState},
    pairs: {data: pairs},
  } = useSelector((state: RootState) => state.swap);

  const [ left, setLeft ] = useState<Token>();
  const [ right, setRight ] = useState<Token>();
  const [ leftAmount, setLeftAmount ] = useState<number>(0);
  // const [ tradeLoading, setTradeLoading ] = useState<boolean>(false);


//   const pollOpenOrders = () => {
//     if (authorized) {
//       dispatch(loadUserOpenOrders());
//       DebotClient.getUserOpenOrders(authorized.userId)
//       .then((value) => {
//         console.log(value);
//         dispatch(setUserOpenOrders(value.orders));
//       });
//     }
//   }

//   // Poll User Open Orders
//   useEffect(() => {
//     if (intervalOpenOrders.current) clearInterval(intervalOpenOrders.current);
//     pollOpenOrders();
//     intervalOpenOrders.current = setInterval(() => {
//       try {
//         pollOpenOrders();
//       } catch (error) {
//         console.error("Error on getOrderBook: " + error);
//       }
//     }, 5000);

//     return () => {
//       if (intervalOpenOrders.current) clearInterval(intervalOpenOrders.current);
//     }
//   }, [authorized, dispatch]);

  useEffect(() => {
    dispatch(loadTokens());
    DebotClient.getTokens()
    .then((value) => {
      console.log(value.tokens);
      dispatch(setTokens(value.tokens));
    });
  }, []);


  useEffect(() => {
    dispatch(loadPairs());
    if (left) {
      DebotClient.getPairs(left.addr)
      .then((value) => {
        console.log(value.tokens);
        if (value.tokens)
        dispatch(setPairs(
          value.tokens.filter((token) => token.addr !== "0:0000000000000000000000000000000000000000000000000000000000000000")
        ));
      });
    }
  }, [left]);

  useEffect(() => {
    if (left) {
      setRight(pairs[0]); //.find((token) => token.symbol === "WEVER")
    }
  }, [pairs]);


  useEffect(() => {
    dispatch(loadRate());
    if (left && right) {

      DebotClient.getRate(left, right, leftAmount*(10**left.decimals))
      .then((value) => {
        console.log(value.rate);
        if (value.rate)
        dispatch(setRate({
          ...value.rate,
          rate: parseFloat(String(value.rate.rate)) / (10**right.decimals)
        }));
      });
    }
  }, [right, leftAmount]);

  useEffect(() => {
    setLeft(tokens.find((token) => token.symbol === "EVER"));
  }, [tokens]);

  return useMemo(
    () => ({
      tokens: tokens,
      pairs: pairs,
      rate: rate,
      rateState: rateState,
      left: left,
      right: right,
      setLeft,
      setRight,
      setLeftAmount
    }),
    [tokens, pairs, left, right, rate, rateState],
  );
};
