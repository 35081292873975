import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MetaDecorator } from "../components";
import { PaylerForm, Widget } from "../layouts";

const Buy = () => {
    return (
      <>
      <MetaDecorator
        title="Ever Surf - Payments "
        description=""
        keywords="Crypto, blockchain testing, blockchain in beta testing"
      />
      <Container fluid className="content-container">
        <Widget>
          <PaylerForm />
        </Widget >
      </Container>
      </>
    );
  };

export default Buy;
