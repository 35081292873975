import React, { useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MetaDecorator } from "../components";
import { SwapForm } from "../layouts";
import { gsap } from "gsap";

const Visual = () => {
  const Layer = () => <svg width="502" height="456" viewBox="0 0 502 456" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 20.2843C0 2.46618 21.5428 -6.45716 34.1421 6.14214L256.001 228.001L34.1421 449.86C21.5428 462.459 0 453.536 0 435.718V20.2843Z" fill="#506DFD"/>
  <path d="M256.001 20.2843C256.001 2.46617 277.544 -6.45716 290.143 6.14214L494.324 210.323C504.087 220.086 504.087 235.915 494.324 245.679L290.143 449.86C277.544 462.459 256.001 453.536 256.001 435.718V228.001V20.2843Z" fill="#506DFD"/>
  </svg>;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousemove", e => {
      gsap.to(".visual svg:not(:first-child)", {
        rotate: (index, target, targets) => {
          return - (1600 + e.clientX) * (index + 1) / 600;
        },
        scale: (index, target, targets) => {
          return 1 - (1 - e.clientY/window.screen.height) * 0.2 * (1 - ((14 - index) + 1) / 15);
        },
      })
    });
  
    return () => {
      gsap.killTweensOf(".visual svg");
      if (ref.current)
        ref.current.outerHTML = ref.current.outerHTML;
    }
  }, [])
  
  return (<div ref={ref} className="visual">
    {[...Array(15)].map((el, index) => <Layer key={index} />)}
  </div>);
}

const Main = () => {
    return (
      <>
      <MetaDecorator
        title="Fast payments and Ever for you · Payments Surf"
        description="Payments framework built on low-fees Everscale blockchain"
        keywords="ever, surf, payments, everscale, swap, trade, dex, exchange, buy, sell, forward, crypto, pamp, nft, checkout"
      />
      <div className="visual-container">
        <Visual/>
      </div>
      <Container fluid className="content-container">
        <Row>
          <Col md={12} lg={12}>
            <h1 className="headline headline-biggest">Fast payments and Ever for you</h1>
            <div className="action action-normal">Payments framework built on low-fees <span><svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.7335 21.6944L21.0388 14.4437V0.805542H7.40062L0.149902 8.11665H13.7277L13.7335 21.6944Z" fill="#506DFD"/></svg>&nbsp;Everscale&nbsp;blockchain</span></div>
          </Col>
        </Row>
      </Container>
      </>
    );
  };

export default Main;
