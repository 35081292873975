import { useState, useRef, useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { Link, NavLink } from "react-router-dom";
import styles from "./Header.module.scss";
import stylesButton from "../form/Input.module.scss";
import classnames from "classnames/bind";
import { Dropdown } from "../../components/dropdown_";
import useOutsideClick from "../../utils/outsideClickCallback";
import { Connect } from "../../patterns";
import { useConnect } from "../../controllers/useConnect";

const cn = classnames.bind(styles);

export const Header = ({location, ...props}: {location: string}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [animated, setAnimated] = useState<boolean>(false);
  const refPicker = useRef<HTMLDivElement>(null);
  
  const { setConnect } = useConnect();

  useOutsideClick(refPicker, () => {});

  const animateBack = (e: any) => {
    if (expanded) {
      setAnimated(true);
      setTimeout(() => setAnimated(false), 300);
    }
    e.stopPropagation();
    setExpanded(expanded ? false : true);
  }

  useEffect(() => {
    document.addEventListener('click', () => {
      if (expanded) {
        setAnimated(true);
        setTimeout(() => setAnimated(false), 300);
      }
      setExpanded(false);
    });
    return () => {
      document.removeEventListener('click', () => {
        if (expanded) {
          setAnimated(true);
          setTimeout(() => setAnimated(false), 300);
        }
        setExpanded(false);
      });
    }
  }, [])

  return (<>
    <header className={cn("header")}>
      <Navbar
        expand="sm"
        expanded={expanded}
        className={cn("navbar")}
        onToggle={(e: any) => {
          if (!e.valueOf()) {
            setAnimated(true);
            setTimeout(() => setAnimated(false), 300);
          }
        }}
      >
        <Nav className={cn("navbar-nav", "me-auto")}>
        <Navbar.Brand className={cn("navbar-brand")}><Link to="/" onClick={(e) => animateBack(e)} className="logo"><span><em>Payments </em><Logo/><em> Surf</em></span></Link></Navbar.Brand>
        <Navbar.Toggle className={cn("navbar-toggler", "d-md-none", {"collapsed": !expanded})} aria-controls="basic-navbar-nav" onClick={(e: any) => animateBack(e)} />
        <Navbar.Collapse onClick={(e) => animateBack(e)} id="basic-navbar-nav" className={cn("navbar-collapse", "d-md-none", {"animated": animated})}>
          <Nav className={cn("navbar-nav", "me-auto")}>
            <NavLink className={cn("nav-link")} to="/buy">Buy Crypto</NavLink>
            <NavLink className={cn("nav-link", "disabled")} to="/sell">Sell Crypto</NavLink>
            <NavLink className={cn("nav-link")} to="/swap">Swap Crypto</NavLink>
            <NavLink className={cn("nav-link")} to="/transfer">Transfer Crypto</NavLink>
            <NavLink className={cn("nav-link", "disabled")} to="/ramps">On- and off-ramps</NavLink>
            <NavLink className={cn("nav-link", "disabled")} to="/route">Cross-chain route</NavLink>
            <NavLink className={cn("nav-link", "disabled")} to="/nft-checkout">NFT checkout</NavLink>
            <NavLink className={cn("nav-link", "disabled")} to="/payments">Payments</NavLink>
          </Nav>
        </Navbar.Collapse>


        <Dropdown
          ref={refPicker}
          isNav={true}
          active={location === "main" ? true : undefined}
          className={cn("nav-item", "navbar-dropdown", "d-md-block", "d-none")}
          title={"Use cases"}
          placeholder={"Use cases"}
          options={[{
            to: "buy",
            title: "Buy Crypto"
          },{
            to: "swap",
            title: "Swap Crypto",
          }
          ,{
            to: "transfer",
            title: "Transfer Crypto"
          },
        ]}
        />

        <Dropdown
          ref={refPicker}
          isNav={true}
          active={location === "main" ? true : undefined}
          className={cn("nav-item", "navbar-dropdown", "d-md-block", "d-none")}
          title={"Business"}
          disabled={true}
          placeholder={"Business"}
          options={[{
            to: "ramps",
            title: "On- and off-ramps",
            disabled: true
          },{
            to: "cross-chain-transfer",
            title: "Cross-chain transfer",
            disabled: true
          },{
            to: "nft-checkout",
            title: "NFT checkout",
            disabled: true
          },{
            to: "payments",
            title: "Payments",
            disabled: true
          },]}
        />
        </Nav>
        {/* <Nav.Link href="https://ever.surf" rel="noreferrer" target="_blank" className={cn("nav-link")}>{<Button variant="transparent" iconPosition={"after"} iconAnimation={"left"} icon={<Icon icon={"arrow-up-right"} />}>Get Surf</Button>}</Nav.Link> */}
      </Navbar>
    </header>
        <Connect
          onConnect={(props: any) => {
            setConnect({address: props.address});
          }}
          onDisconnect={() => {
            setConnect({address: ""});
          }}
        />
    </>
  );
};

export default Header;
