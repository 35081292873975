import styles from "./CardBasic.module.scss";
import classnames from "classnames/bind";
import { Panel } from "../Panel";
import { FlexContainer, Flex } from "../Flex";
import { ReactNode } from "react";

const cnb = classnames.bind(styles);

export const CardBasic = ({ className = "", alignRight = "center", subtitle, status, title, description, action}: {
  className?: string,
  status?: "loading" | "disabled" | undefined,
  subtitle?: ReactNode,
  alignRight?: "flex-start" | "flex-end" | "center",
  title?: ReactNode,
  description?: ReactNode,
  action?: ReactNode,
}) => {
  return <Panel className={cnb("card-basic", (status ? "card-basic-" + status : null), className)} type="content">
    <FlexContainer
      direction="row"
      justify="space-between"
      align="stretch"
    >
      <Flex>
        <FlexContainer
           className={cnb("card-basic-left")}
          direction="column"
          justify="flex-start"
          align="flex-start"
        >
          {subtitle !== null && <Flex className={cnb("paragraph", "paragraph-medium")}>{subtitle}</Flex>}
          {title !== null && <Flex className={cnb("paragraph", "paragraph-large")}>{title}</Flex>}
          {description !== null && <Flex className={cnb("paragraph", "paragraph-medium")}>{description}</Flex>}
        </FlexContainer>
      </Flex>
      {action !== null && <FlexContainer
        direction="column"
        justify={alignRight}
        align="flex-end"
      >
        {action}
      </FlexContainer>}
    </FlexContainer>
  </Panel>;
};

export default CardBasic;
