import { ActionTypes } from "../../types/action-types";
import {
  SET_EVER_ADDRESS,
  SET_EVER_TYPE,
} from "../actions/constants";

import {
  Token,
  Rate,
  PairsQuantum
} from "../../interfaces";

import {
  State,
  getInitialState,
  setLoadingState,
  setSuccessState
} from '../../utils/store/PromiseUtils';
import { address } from "../../types";

interface IInitialState {
  address: State<address>,
  type: State<string>,
}

const initialState: IInitialState = {
  address: getInitialState<address>(""),
  type: getInitialState<address>("")
};

export const everReducer = (
  state: IInitialState = initialState,
  { type, payload }: ActionTypes
):IInitialState => {
  switch (type) {
    case SET_EVER_ADDRESS:
      return setSuccessState<string, IInitialState>(state, 'address', payload as address);
    case SET_EVER_TYPE:
      return setSuccessState<string, IInitialState>(state, 'type', payload as string);
    default:
      return state;
  }
};