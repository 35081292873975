export const Tokens = {
	"name": "Broxus Bridge connected assets",
	"token": {
		"0:eb2ccad2020d9af9cec137d3146dde067039965c13a27d97293c931dae22b2b9": {
			"proxy": "0:591f98d43d71f9bb5ab63cf582f82edc949f31c5cddc33963510640fda3819b8",
			"vaults": [
				{
					"chainId": "1",
					"vault": "0x032d06b4cc8a914b85615acd0131c3e0a7330968",
					"ethereumConfiguration": "0:d01b67b1f7eef5cca030ae438fcbab6c4a52d2a6ca535937d14f7b6530378516",
					"depositType": "default"
				},
				{
					"chainId": "137",
					"vault": "0xced734f47613e2484fd9ee6f76afcb866bc4d6fa",
					"ethereumConfiguration": "0:d9cba67dd6321a1f42320440c242927f46bda324c7016d888f20491a630cfab5",
					"depositType": "default"
				},
				{
					"chainId": "250",
					"vault": "0x334d7e33f3b0ac04309b17ca56bcb0f0fa3d0efd",
					"ethereumConfiguration": "0:7d633a0896a81696fc9d0eae8a2422842ca85231455244c7008a7393003d2e68",
					"depositType": "default"
				},
				{
					"chainId": "56",
					"vault": "0xad4c25634e3818d674ddc07b98135ed6db7ef307",
					"ethereumConfiguration": "0:fba596b07c181792db8d2f2b37ff986db686b2029aeb0ac3adfb44c4a78374e1",
					"depositType": "default"
				},
				{
					"chainId": "1",
					"vault": "0x032d06b4cc8a914b85615acd0131c3e0a7330968",
					"ethereumConfiguration": "0:62fa72dc5ae8d80f00001bb7629ebddfdc5ac33754c2247eef7673bf19376dba",
					"depositType": "credit"
				},
				{
					"chainId": "56",
					"vault": "0xad4c25634e3818d674ddc07b98135ed6db7ef307",
					"ethereumConfiguration": "0:f2d645d1c724d335e76cdab92874d571dceddf184289fba172ddc5d40ca4ccaf",
					"depositType": "credit"
				},
				{
					"chainId": "137",
					"vault": "0xced734f47613e2484fd9ee6f76afcb866bc4d6fa",
					"ethereumConfiguration": "0:67b5f82fce7f4d2358f5451a0e2ec1e4cf416056531196349cde4ebb26bfec20",
					"depositType": "credit"
				},
				{
					"chainId": "250",
					"vault": "0x334d7e33f3b0ac04309b17ca56bcb0f0fa3d0efd",
					"ethereumConfiguration": "0:2f379fb795f3a1065da8c260ba211fd33f093b4e36c427a8192cc69a8d84657f",
					"depositType": "credit"
				}
			]
		},
		"0:a519f99bb5d6d51ef958ed24d337ad75a1c770885dcd42d51d6663f9fcdacfb2": {
			"proxy": "0:a3023d7812aff9cafa73365853bdc11eeb010f09cd657de6e802adf16cfcd282",
			"vaults": [
				{
					"chainId": "1",
					"vault": "0x81598d5362eac63310e5719315497c5b8980c579",
					"ethereumConfiguration": "0:2373a34390fe39bc49d229f179debe8b8425275585394e20172fe464eccc1df4",
					"depositType": "default"
				},
				{
					"chainId": "137",
					"vault": "0xd33492080f2d3a89ae500a3b3bc0e076713a3cbb",
					"ethereumConfiguration": "0:da8cfbab387eee0ce223069828a4414e1ddbbb92681c56f5a4239d7efd7e430b",
					"depositType": "default"
				},
				{
					"chainId": "56",
					"vault": "0x5d767d4e250b5c8640cb2bf7e7cd3acaeb7768e1",
					"ethereumConfiguration": "0:d1ce1345ac20d5992faf04f4142630ea1f153cda5210765516447e81972cfaf2",
					"depositType": "default"
				},
				{
					"chainId": "1",
					"vault": "0x81598d5362eac63310e5719315497c5b8980c579",
					"ethereumConfiguration": "0:8a9c64dc2f753b5cae07e8bccad1b8841d8b19a2325c0c34f865f1e372ec3e55",
					"depositType": "credit"
				},
				{
					"chainId": "56",
					"vault": "0x5d767d4e250b5c8640cb2bf7e7cd3acaeb7768e1",
					"ethereumConfiguration": "0:79c92057392371647e3578c93650a5692aa844f427af3a9c92d204c911c708ef",
					"depositType": "credit"
				},
				{
					"chainId": "137",
					"vault": "0xd33492080f2d3a89ae500a3b3bc0e076713a3cbb",
					"ethereumConfiguration": "0:c1bddaaa8aed4b4fb393dae65f5b0bece95a0184e1048e9bc4b78ffc2c3ee119",
					"depositType": "credit"
				}
			]
		},
		"0:c37b3fafca5bf7d3704b081fde7df54f298736ee059bf6d32fac25f5e6085bf6": {
			"proxy": "0:f2cd0f4bf7269960dcddf2eb8c1c37279db9a6d1f0987bec4b3ce91fc40b6e79",
			"vaults": [
				{
					"chainId": "1",
					"vault": "0xf8a0d53ddc6c92c3c59824f380c0f3d2a3cf521c",
					"ethereumConfiguration": "0:ba76d3d6a381bcb40739b0649e486d34d8024e8fd8f8a60db2278ad464967090",
					"depositType": "default"
				},
				{
					"chainId": "137",
					"vault": "0xf504e9a7511f1af03f8e8c6800a05fb9d43481f2",
					"ethereumConfiguration": "0:2cad4d409f50fdf361c06abe9671f7e806c42e5c039d6bf91d4ff6de523b7100",
					"depositType": "default"
				},
				{
					"chainId": "250",
					"vault": "0xb05a3640132642e6297980376129354ee21a9fc6",
					"ethereumConfiguration": "0:b873471227f89fc80ee89cbe33cbd98a2cad47fc514f19caf903f76e78811b28",
					"depositType": "default"
				},
				{
					"chainId": "56",
					"vault": "0x65950dd2a3d8316c197bda1a353aed046035b1c9",
					"ethereumConfiguration": "0:3a6f9c14dd680a639583f4ad4cbeafed590e48b27e4a9ea13a937ebd5601b9d5",
					"depositType": "default"
				},
				{
					"chainId": "1",
					"vault": "0xf8a0d53ddc6c92c3c59824f380c0f3d2a3cf521c",
					"ethereumConfiguration": "0:330968ee73b469ee7027bcff8ac76d85419418bfe1be88b70cddce1a95802830",
					"depositType": "credit"
				},
				{
					"chainId": "56",
					"vault": "0x65950dd2a3d8316c197bda1a353aed046035b1c9",
					"ethereumConfiguration": "0:66728176bf4b920d7921683e22452d8b95f665de4237a83003f03ae54b3f0823",
					"depositType": "credit"
				},
				{
					"chainId": "137",
					"vault": "0xf504e9a7511f1af03f8e8c6800a05fb9d43481f2",
					"ethereumConfiguration": "0:90c00ef7e091f69d31174fececca291ab04d3f16f3ebc3c50ebf52f4afc1eb98",
					"depositType": "credit"
				},
				{
					"chainId": "250",
					"vault": "0xb05a3640132642e6297980376129354ee21a9fc6",
					"ethereumConfiguration": "0:163f95e2779392f4817a7486464166ac2eeb9c6c8ad2a9e751cdd8b7a3be8c12",
					"depositType": "credit"
				}
			]
		},
		"0:2ba32b75870d572e255809b7b423f30f36dd5dea075bd5f026863fceb81f2bcf": {
			"proxy": "0:0325f3834bca7cdcea148bb1e7ae73a2e235c9eae3ec2d9c3f5851aca7e35749",
			"vaults": [
				{
					"chainId": "1",
					"vault": "0xf67d8b970a0a955b5ff2a80b8dfd6aff21567633",
					"ethereumConfiguration": "0:384517062a592899c4070e6afbba3c28c9cca10c104acb658877cb3cb574c80c",
					"depositType": "default"
				},
				{
					"chainId": "137",
					"vault": "0x1fa28c9cb44d2853afd0d932c3805221fab95a8b",
					"ethereumConfiguration": "0:7a013ebe43d07f70f4c3f66687de5ee16a9ffc4db1d528d3e0fdf1e7305318b0",
					"depositType": "default"
				},
				{
					"chainId": "250",
					"vault": "0x8f9d8cfd0b018b1939bb24e2ce48a9e4040fb68a",
					"ethereumConfiguration": "0:b371b58872958ae7f75c251dfe9c00ee0b6541b0dee22a4ee60577f584f87d86",
					"depositType": "default"
				},
				{
					"chainId": "56",
					"vault": "0x0cc7096480e78409aec14795a96efeaf3e0b4b38",
					"ethereumConfiguration": "0:e3d6c47d92a3a1ac51cf6ecc34354838dc0939f6e882aa4af99e220b209f0b2e",
					"depositType": "default"
				},
				{
					"chainId": "1",
					"vault": "0xf67d8b970a0a955b5ff2a80b8dfd6aff21567633",
					"ethereumConfiguration": "0:8092b6c0c76f39d26c5ff181f9a514d7c10942da59e930f4fe4e687f1fecdc7d",
					"depositType": "credit"
				},
				{
					"chainId": "56",
					"vault": "0x0cc7096480e78409aec14795a96efeaf3e0b4b38",
					"ethereumConfiguration": "0:a68f50e8920cbadfbf6766bd5b1f6bdac500afc9f1e7cceb68a4615918ab5551",
					"depositType": "credit"
				},
				{
					"chainId": "137",
					"vault": "0x1fa28c9cb44d2853afd0d932c3805221fab95a8b",
					"ethereumConfiguration": "0:c1dc8f5ce08e12ab8a788aaf7330749017810487d10d0f367eeae89004d8c5a4",
					"depositType": "credit"
				},
				{
					"chainId": "250",
					"vault": "0x8f9d8cfd0b018b1939bb24e2ce48a9e4040fb68a",
					"ethereumConfiguration": "0:a7a3ad25ac8a7714709986a30e77824e3d0217e51bedb230ab1ebebae7d0f74f",
					"depositType": "credit"
				}
			]
		},
		"0:59b6b64ac6798aacf385ae9910008a525a84fc6dcf9f942ae81f8e8485fe160d": {
			"proxy": "0:600f9ab6f5f52fbfcab012532a6e2e7d5a889362329073f22f6a642c397da4c9",
			"vaults": [
				{
					"chainId": "1",
					"vault": "0x55046f53eb9fa069286969d73432b769f068e1fc",
					"ethereumConfiguration": "0:8f736dbab2eed3053c8c1a7ce066f75f51d436f5deb357419a5e8de4774f9aa7",
					"depositType": "default"
				},
				{
					"chainId": "137",
					"vault": "0x356b37e007564fd37b957f946a246871bf827ea2",
					"ethereumConfiguration": "0:50133971e481a6caf29acff4dc96e144d9e9be2701469cda4efdc1ee16372889",
					"depositType": "default"
				},
				{
					"chainId": "250",
					"vault": "0x5115cd7e0dd0886c11e28e54ad2422f61544f314",
					"ethereumConfiguration": "0:7038ab1ac5acd0fd76e904f1e7e6fb351d3f46b436d8a5d24ea44eb670cf0e48",
					"depositType": "default"
				},
				{
					"chainId": "56",
					"vault": "0x5b1e3e9f24455debd6f3a0c4b8bc6b46ca57f68c",
					"ethereumConfiguration": "0:d216c089b936595f54d4d4c562b200abb3b8932cf90b18911a7df006e0b479dd",
					"depositType": "default"
				},
				{
					"chainId": "1",
					"vault": "0x55046f53eb9fa069286969d73432b769f068e1fc",
					"ethereumConfiguration": "0:44554c6f3a9ebef0d8507043ee9c5215c5b27185ba29d5a88c63142b59501998",
					"depositType": "credit"
				},
				{
					"chainId": "56",
					"vault": "0x5b1e3e9f24455debd6f3a0c4b8bc6b46ca57f68c",
					"ethereumConfiguration": "0:af7dfb446a58dcd6b512599571957f2984433285620ca832c6ba5f084dde5acf",
					"depositType": "credit"
				},
				{
					"chainId": "137",
					"vault": "0x356b37e007564fd37b957f946a246871bf827ea2",
					"ethereumConfiguration": "0:471c0ef6e56ebb4e6f6735286c6eca859aae5a973c8568843399a9f6d08dccdc",
					"depositType": "credit"
				},
				{
					"chainId": "250",
					"vault": "0x5115cd7e0dd0886c11e28e54ad2422f61544f314",
					"ethereumConfiguration": "0:42d94730ae33c48badb93ff222c4976488532967a8abd2940b00364506a3d5fe",
					"depositType": "credit"
				}
			]
		},
		"0:1e6e1b3674b54753864af7b15072566ce632965bd83bab431a8ff86d68cf1657": {
			"proxy": "0:18987794347bb73eae04d8eb420629058cf116cfcdc9ea7850666c7ce53609d5",
			"vaults": [
				{
					"chainId": "1",
					"vault": "0x8d589f403d5232e37bd30e02260ea6b6ad061f3f",
					"ethereumConfiguration": "0:3a969e9e2add5f41342207900b06f7a8de9c60d6d982aca80c422e1753664dc9",
					"depositType": "default"
				}
			]
		},
		"0:efed9f9a7e6c455ee60829fd003b2f42edda513c6f19a484f916b055e9aa58d2": {
			"proxy": "0:393f2eac59064e38661b8bfd61256f1acbf6160fcf0e9ec37d0115ee6dcde59e",
			"vaults": [
				{
					"chainId": "1",
					"vault": "0xf2403a61c7a97a1a1b94a225173f6dd03614b907",
					"ethereumConfiguration": "0:db3e41df69e4d5865b83545dc9cc62936565dbb66f3922235e338d535bd911d9",
					"depositType": "default"
				}
			]
		},
		"0:c14e2f026feaae0f99b92c04ee421051a782fff60156ac8a586a12f63d7facef": {
			"proxy": "0:10653361a4e3f43b81befbec7ea97e1137bff1233fe2515abf4d6ac42fcc6909",
			"vaults": [
				{
					"chainId": "1",
					"vault": "0x4dee4b939371a9957f5f36a26341f40a88eed0cc",
					"ethereumConfiguration": "0:e1decc75ae4475b0c25c12f999346119011ef4f37404c989f68df8915037692e",
					"depositType": "default"
				}
			]
		},
		"0:f48054939064d686a9ad68d96d9ab79e409b095557c06ab7f073097dade7057f": {
			"proxy": "0:f0e380fa8a48adb45e73fa499c4346402808c31a45e69f9c9ef2544e4e6d1e86",
			"vaults": [
				{
					"chainId": "1",
					"vault": "0x9c217efa90a6a2bb5f0543a0be7d682ce6cf5275",
					"ethereumConfiguration": "0:fd83a458e494bb0ba692a4b9f17d0627bc185d2bf3e950cb8c9136e4eb47daa8",
					"depositType": "default"
				}
			]
		}
	}
}

export const Credit = {
	"name": "Broxus Hidden Bridge creditors",
	"creditors": [
		{
			"address": "0:5ae128e08b2c17428629e092c1a7bd5c77a83a27fa3b833a31c2eb3d704d7f68",
			"creditBody": "5800000000"
		}
	]
}

export const EverscaleTokens = {
    "$schema": "https://raw.githubusercontent.com/broxus/everscale-assets-upgrade/master/schemas/manifest.json",
    "name": "Everscale assets upgrade",
    tokens: [
      {
        "symbol": "BRIDGE",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/BRIDGE/logo.svg",
        "rootV4": "0:a453e9973010fadd4895e0d37c1ad15cba97f4fd31ef17663343f79768f678d9",
        "rootV5": "0:f2679d80b682974e065e03bf42bbee285ce7c587eb153b41d761ebfd954c45e1",
        "proxy": "0:a43e827e20fc7a2a4fdeb334fa0ef8c57e6ccbddeca0591b932957ae05f2aa14"
      },
      {
        "symbol": "USDT",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/USDTv3/logo.svg",
        "rootV4": "0:751b6e22687891bdc1706c8d91bf77281237f7453d27dc3106c640ec165a2abf",
        "rootV5": "0:a519f99bb5d6d51ef958ed24d337ad75a1c770885dcd42d51d6663f9fcdacfb2",
        "proxy": "0:a3023d7812aff9cafa73365853bdc11eeb010f09cd657de6e802adf16cfcd282"
      },
      {
        "symbol": "USDC",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/USDCv3/logo.svg",
        "rootV4": "0:1ad0575f0f98f87a07ec505c39839cb9766c70a11dadbfc171f59b2818759819",
        "rootV5": "0:c37b3fafca5bf7d3704b081fde7df54f298736ee059bf6d32fac25f5e6085bf6",
        "proxy": "0:f2cd0f4bf7269960dcddf2eb8c1c37279db9a6d1f0987bec4b3ce91fc40b6e79"
      },
      {
        "symbol": "DAI",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/DAIv3/logo.svg",
        "rootV4": "0:95934aa6a66cb3eb211a80e99234dfbba6329cfa31600ce3c2b070d8d9677cef",
        "rootV5": "0:eb2ccad2020d9af9cec137d3146dde067039965c13a27d97293c931dae22b2b9",
        "proxy": "0:591f98d43d71f9bb5ab63cf582f82edc949f31c5cddc33963510640fda3819b8"
      },
      {
        "symbol": "WBTC",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/WBTCv3/logo.svg",
        "rootV4": "0:6e76bccb41be2210dc9d7a4d0f3cbf0d5da592d0cb6b87662d5510f5b5efe497",
        "rootV5": "0:2ba32b75870d572e255809b7b423f30f36dd5dea075bd5f026863fceb81f2bcf",
        "proxy": "0:0325f3834bca7cdcea148bb1e7ae73a2e235c9eae3ec2d9c3f5851aca7e35749"
      },
      {
        "symbol": "WETH",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/WETHv3/logo.svg",
        "rootV4": "0:45f682b7e783283caef3f268e10073cf08842bce20041d5224c38d87df9f2e90",
        "rootV5": "0:59b6b64ac6798aacf385ae9910008a525a84fc6dcf9f942ae81f8e8485fe160d",
        "proxy": "0:600f9ab6f5f52fbfcab012532a6e2e7d5a889362329073f22f6a642c397da4c9"
      },
      {
        "symbol": "UNI-V2-USDT-WTON",
        "rootV4": "0:53abe27ec16208973c9643911c35b5d033744fbb95b11b5672f71188db5a42dc",
        "rootV5": "0:1e6e1b3674b54753864af7b15072566ce632965bd83bab431a8ff86d68cf1657",
        "proxy": "0:18987794347bb73eae04d8eb420629058cf116cfcdc9ea7850666c7ce53609d5"
      },
      {
        "symbol": "FRAX",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/FRAX/logo.svg",
        "rootV4": "0:f8b0314571f5f00f6d9a61a914b9b5e1d910442d09b80c27efeb46631d74e961",
        "rootV5": "0:efed9f9a7e6c455ee60829fd003b2f42edda513c6f19a484f916b055e9aa58d2",
        "proxy": "0:393f2eac59064e38661b8bfd61256f1acbf6160fcf0e9ec37d0115ee6dcde59e"
      },
      {
        "symbol": "FXS",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/FXS/logo.svg",
        "rootV4": "0:0cddd021d2488c882041a31ba44e4ee69643a45223f068571e05b5a4c45bb7f6",
        "rootV5": "0:c14e2f026feaae0f99b92c04ee421051a782fff60156ac8a586a12f63d7facef",
        "proxy": "0:10653361a4e3f43b81befbec7ea97e1137bff1233fe2515abf4d6ac42fcc6909"
      },
      {
        "symbol": "DAF",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/DAF/logo.svg",
        "rootV4": "0:bf1c7c0e8a187d9d5ba6069bf768b69a982df8b22ef8430b31dcc4f97263507e",
        "rootV5": "0:f48054939064d686a9ad68d96d9ab79e409b095557c06ab7f073097dade7057f",
        "proxy": "0:f0e380fa8a48adb45e73fa499c4346402808c31a45e69f9c9ef2544e4e6d1e86"
      },
      {
        "symbol": "EUPi",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/EUPi/logo.svg",
        "rootV4": "0:f4a912b0c3be422e02c0a8295590671cae5b38c75d397da8d1da33882888bbcb",
        "rootV5": "0:0cfa60f9454b1b619938c4da6a138b1cc62da937b3f6c0506405daf2a88e0115",
        "proxy": "0:f93b0789bb397c2b090dc9dec0f595b2bf34a157a782452dcd5074c51636312e"
      },
      {
        "symbol": "SOON",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/SOON/logo.svg",
        "rootV4": "0:a88b380064dad1520f09e6ed1162ce79272503a6d443fc938faeaabd135c294d",
        "rootV5": "0:a15e6a7a91c331ddcd85675ca64d5a7db8c7e94b35806b2e05bf4bce29d21023",
        "proxy": "0:1078fc30e3acd94c1855224ea4d2d94f7677f6c9bfb2cf79b0962490bc40d726"
      },
      {
        "symbol": "GRE",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/GRE/logo.svg",
        "rootV4": "0:5a36d8f9384f63321d49fd98e1a7818b28d9496d1daf3328325560ded3d86806",
        "rootV5": "0:fde4c629d6447fecd86d2cffe363d5f334030351022bad019e0f472212e9dc99",
        "proxy": "0:de42136038cb331499cc104da47bbc63d790e403def83ce576dea6deb06cd7cc"
      },
      {
        "symbol": "EVS",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/EVS/logo.svg",
        "rootV4": "0:4600a14ac4d37836993ea7040ee8e78afc7f246bca3c81367d50f5046df7ee8c",
        "rootV5": "0:e5d964bf76b90a29ca11ff03a0402eeca6a1c79a0fb4d8a2f06a67be0537a6bf",
        "proxy": "0:b7f5e80ec25cd08536e2ff18f137d36a30a4f98060bf05b78c78101e4bf27313"
      },
      {
        "symbol": "BAPBAPA",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/BAPBAPA/logo.svg",
        "rootV4": "0:60e0e4a95f54a7d4c9d3668b9a545cc513e96e6a9826f514b1f88b1a4145c693",
        "rootV5": "0:9e0350c13cafe2bb55d906553fde5f357612e6dcb46c0c3440c7bfdda273ffdd",
        "proxy": "0:f6ceca4557593001bdf39b8512c29ce5f334f1c15713fbec0d092956a5b4a4bc" 
      },
      {
        "symbol": "DUSA",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/DUSA/logo.svg",
        "rootV4": "0:76471256fbccd1c2734ac20cd6ccc4d0322286d4b6519458540de303c58a7e12",
        "rootV5": "0:b3ed4b9402881c7638566b410dda055344679b065dce19807497c62202ba9ce3",
        "proxy": "0:10915b210558d7ce04559109bca77a4c1dc18844d3b88eca3919643e5fc1d39a"
      },
      {
        "symbol": "DECO",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/DECO/logo.svg",
        "rootV4": "0:62d9fe7169fc0a49b2d8eb4c2972800fbad8b885171ea0849ebc402a24ad31c3",
        "rootV5": "0:d3bd7e235c74cea907aa65cc7a3f0f533334a65f61bc9b85bda50300bec6ce77",
        "proxy": "0:74a8fc6a0e1625b412382e954a5cb21ff7527b1c65a6eddc198c1cf802be63a6"
      },
      {
        "symbol": "COLA",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/COLA/logo.svg",
        "rootV4": "0:967c87a81c2ad6a489fe006f831a11ffd8ac5bc6f7dd4e107a27e0e6a7728144",
        "rootV5": "0:9dd2cd82cbfd74a963be1ef1fd1a1f3e25f60e0b8c742f8b862df378b9a5e265",
        "proxy": "0:9d09ed787c99143c125ecf6b3ace7f6056d6b0180cb69d5a306b8c34dcf0225b"
      },
      {
        "symbol": "MOON",
        "logoURI": "https://raw.githubusercontent.com/broxus/ton-assets/master/icons/MOON/logo.svg",
        "rootV4": "0:ced4179b55a060cc98978956c08fb7ea6495d5dd4258ea59b5f320affd15ed32",
        "rootV5": "0:b5ff077d8ac0160559bd3c945a2a824cda12ba93ae90c2697c890656d52fc7d0",
        "proxy": "0:661138e46a596433054ce5bcec2ac30a5730944b76c2ee5a6484bdf2bd702f56"
      }
    ]
  }


export const Providers = {
	"name": "Broxus Hidden Bridge creditors",
	"creditors": [
		{
			"address": "0:5ae128e08b2c17428629e092c1a7bd5c77a83a27fa3b833a31c2eb3d704d7f68",
			"creditBody": "5800000000"
		}
	]
}

export const ChainEndpoints = [
	{
		"name": "Ethereum",
		"url": "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
		"explorer": "https://etherscan.io/",
		"id": "1"
	},
	{
		"name": "Binance Smart Chain",
		"url": "https://bsc-dataseed.binance.org/",
		"explorer": "https://bscscan.com/",
		"id": "56"
	},
	{
		"name": "Fantom Opera",
		"url": "https://rpc.ftm.tools/",
		"explorer": "https://ftmscan.com/",
		"id": "250"
	},
	{
		"name": "Harmony Mainnet",
		"url": "https://api.harmony.one",
		"explorer": "https://explorer.harmony.one/",
		"id": "1666600000"
	},
	{
		"name": "Polygon MATIC",
		"url": "https://polygon-rpc.com/",
		"explorer": "https://polygonscan.com/",
		"id": "137"
	}
]