import { useEffect, useState } from "react";
import { WidgetColors } from '../types';
import { useQuery } from './useQuery';

export const useWidgetParams = (): WidgetColors => {
  let query: URLSearchParams = useQuery();

  const [widgetColors, setWidgetColors] = useState<WidgetColors>({});

  useEffect(() => {
    const root = document.documentElement;
    /* Adding widget scoping class to avoid global CSS contamination */
    root.classList.add("widget-theme");
  });

  useEffect(() => {
    const root = document.documentElement;

    const colors: WidgetColors = {};

    /* Widget background colours - Primary, Secondary and Tertiary */
    if (query.get('backP')) {
      root?.style.setProperty(
        "--widget-bg-color-primary",
        `#${query.get('backP')}`
      );
      colors.backP = query.get('backP') as string;
    }
    if (query.get('backS')) {
      root?.style.setProperty(
        "--widget-bg-color-secondary",
        `#${query.get('backS')}`
      );
      colors.backS = query.get('backS') as string;

    }
    if (query.get('backT')) {
      root?.style.setProperty(
        "--widget-bg-color-tertiary",
        `#${query.get('backT')}`
      );
      colors.backT = query.get('backT') as string;
    }
    /* Widget text colours - Primary, Secondary and Tertiary */
    if (query.get('textP')) {
      root?.style.setProperty(
        "--widget-text-color-primary",
        `#${query.get('textP')}`
      );
      colors.textP = query.get('textP') as string;
    }
    if (query.get('textS')) {
      root?.style.setProperty(
        "--widget-text-color-secondary",
        `#${query.get('textS')}`
      );
      colors.textS = query.get('textS') as string;
    }
    if (query.get('textT')) {
      root?.style.setProperty(
        "--widget-text-color-tertiary",
        `#${query.get('textT')}`
      );
      colors.textT = query.get('textT') as string;
    }
    /* Widget accent colour */
    if (query.get('accent')) {
      root?.style.setProperty(
        "--widget-accent-color",
        `#${query.get('accent')}`
      );
      colors.accent = query.get('accent') as string;
    }
    /* Widget icons colour */
    if (query.get('icons')) {
      root?.style.setProperty(
        "--widget-icons-color",
        `#${query.get('icons')}`
      );
      colors.icons = query.get('icons') as string;
    }
    setWidgetColors(colors);
  }, [query, setWidgetColors]);

  return widgetColors;
};
