import styles from "./Loader.module.scss";
import classnames from "classnames/bind";

const cn = classnames.bind(styles);

export const LoaderDots = ({className}: {className?: string}) => (
  <div className={cn("loader-dots", className)}>
    <div></div>
    <div></div>
    {/* <div></div> */}
  </div>
);


export const LoaderThreeDots = ({className}: {className?: string}) => (
  <div className={cn("loader-three-dots", className)}>
    <div>&middot;</div>
    <div>&middot;</div>
    <div>&middot;</div>
  </div>
);
export default LoaderDots;
