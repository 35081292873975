import { combineReducers } from "redux";

import {
  swapReducer,
  everReducer
} from "./index";

export const rootReducer = combineReducers({
  swap: swapReducer,
  ever: everReducer,
});
