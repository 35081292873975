import { FormikProvider, useFormik } from 'formik';
import { ReactNode, useEffect, useRef, useState } from "react";
//import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import * as Yup from 'yup';

import { useSwap } from "../../controllers";

import classnames from "classnames/bind";
import QRCodeStyling from "qr-code-styling";

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/scss'; // core Swiper
import 'swiper/scss/pagination'; // Pagination module

import { Flex, FlexContainer, Icon, LoaderDotsText, ModalSheet, Panel } from "../../components";
import { Button } from "../../components/button_";
import { SwapPicker } from "../../components/Form";
import { address, Token } from "../../interfaces";
import { WidgetColors } from '../../types';
import { floatDecimals } from "../../utils";
import styles from './Form.module.scss';
import stylesSwap from './SwapForm.module.scss';

const cnb = classnames.bind(styles);
const cnbswap = classnames.bind(stylesSwap);

interface FormValues {
  majorAmount: number | "";
  major: address;
  minorAmount: number | "";
  minor: address;
  wallet: string;
}

const Loader = () => (
  <svg className={styles.spinner}>
    <circle cx="20" cy="20" r="12"></circle>
  </svg>
);

export const SlideWrapper = ({ direction, children }: { direction: "next" | "prev", children: ReactNode }) => {
  const swiper = useSwiper();
  return (
    <div className={cnb("swiper-button-wrapper")} onClick={() => direction === "prev" ? swiper.slidePrev() : swiper.slideNext()}>{children}</div>
  );
}

type SwapFormProps = {
  widgetColors?: WidgetColors;
}

export const SwapForm = ({ widgetColors }: SwapFormProps) => {
  const {
    tokens,
    pairs,
    rate,
    rateState,
    left,
    right,
    setLeft,
    setRight,
    setLeftAmount
  } = useSwap();

  const [sessionId, setSessionId] = useState<string>('');
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const refQr = useRef<HTMLDivElement>(null);

  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    type: "svg",
    margin: 0,
    qrOptions: {
      typeNumber: 0,
      mode: "Byte",
      errorCorrectionLevel: "L"
    },
    imageOptions: {
      hideBackgroundDots: false,
      imageSize: 0,
      margin: 0
    },
    dotsOptions: {
      type: "rounded",
      color: widgetColors?.icons ? `#${widgetColors.icons}` : "#ffffff"
    },
    backgroundOptions: {
      color: "transparent"
    },
    image: "",
    cornersSquareOptions: {
      type: undefined,
      color: "#ffffff"
    },
    cornersDotOptions: {
      type: undefined,
      color: "#ffffff"
    }
  });

  const validationSchema = Yup.object().shape({
    majorAmount: Yup.number()
      .typeError('Nice try😄')
      .required('')
      .positive('Nice try😄')
      .min(0, 'Minimum 50'),
    minorAmount: Yup.number()
      .typeError('Nice try😄')
      .required('')
      .positive('Nice try😄')
      .min(0, 'Minimum 100'),
    major: Yup.string()
      .typeError('Nice try😄')
      .required(''),
    minor: Yup.string()
      .typeError('Nice try😄')
      .required(''),
    wallet: Yup.string()
      .matches(/^0:[a-f0-9]{64,64}$/, "Doesn't look like a valid TON wallet ID")
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      majorAmount: 1,
      major: left?.addr || "",
      minorAmount: "",
      minor: right?.addr || "",
      wallet: ''
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: ({ major }, { setSubmitting }) => {
      if (formik.values.majorAmount > 600) {

        // if (accessToken) {
        //   console.log("accessToken si here and it's " + accessToken);
        //   setSubmitting(false);
        //   // setSumsubActive(true);
        // } else {
        //   console.log("Retrieving accessToken");
        //   setSubmitting(true);
        //   try {
        //     // getToken(sessionId).then((value: any) => {
        //     //   setCookie("sumsubSessionToken", value);
        //     //   setAccessToken(value);
        //     //   setSumsubActive(true);

        //     // });
        //   } catch (error) {
        //     console.error('Error while getting a token: ' + error);
        //   }
        // }
      } else if (sessionId) {
        // console.log("accessToken si here and it's " + accessToken);
        //setSubmitting(false);
        //setSumsubActive(true);
        //launchWebSdk(accessToken);
        // setCheckoutActive(true);
      }
    }
  });

  const [url, setUrl] = useState<string>();
  const [invertRate, setInvertRate] = useState<boolean>(false);

  const DEBOT_ADDRESS = "0:fb69ca9f22e9ce54d926176ca68614644521050c77dc53d644081c51ef3a892c";

  useEffect(() => {
    if (left && right && formik.values.majorAmount)
      setUrl(`https://uri.ever.surf/debot/${DEBOT_ADDRESS}?manifest=${Buffer.from(buildSwapManifest(left, right, formik.values.majorAmount * (10 ** left.decimals))).toString("base64").replaceAll('/+', '_-').replaceAll('=', '')}&net=mainnet`);
  }, [formik.values.majorAmount, left, right])

  const buildSwapManifest = (
    left: Token,
    right: Token,
    amount: number,
  ): string => JSON.stringify({
    "version": 0,
    "debotAddress": "0:fb69ca9f22e9ce54d926176ca68614644521050c77dc53d644081c51ef3a892c",
    "init": {
      "method": "invokeSwap",
      "params": {
        "right": right.addr,
        "left": left.addr,
        "amount": amount
      }
    },
    "abi": {
      "ABI version": 2,
      "version": "2.2",
      "header": ["time"],
      "functions": [
        {
          "name": "onSwap",
          "inputs": [
            { "name": "status", "type": "uint8" }
          ],
          "outputs": [
          ]
        }
      ]
    },
    "quiet": false,
    "chain": []
  });

  useEffect(() => {
    if (tokens.length && formik.values.majorAmount && rate) {
      formik.setFieldValue("minorAmount", floatDecimals(formik.values.majorAmount * rate.rate, 3));
    }
  }, [formik.values.majorAmount, tokens.length, rate]);

  useEffect(() => {
    if (refQr.current) {
      qrCode.update({
        data: url
      });
      refQr.current.innerHTML = '';
      qrCode.append(refQr.current);
      if (refQr.current.querySelector("svg")) refQr.current.querySelector("svg")!.setAttribute("viewBox", "0 0 300 300");
    }
  }, [url, confirmed]);

  useEffect(() => {
    if (tokens.length && formik.values.majorAmount && rate && right && (formik.values.minorAmount !== formik.values.majorAmount * rate.rate)) {
      if (rateState.isLoading())
        formik.setFieldValue("minorAmount", "");
      else
        formik.setFieldValue("minorAmount", floatDecimals(formik.values.majorAmount * rate.rate, 3));
    }
  }, [formik.values.majorAmount, formik.values.minorAmount, rate, rateState, right, tokens.length]);

  useEffect(() => {
    const newRight: Token | undefined = tokens.find((token) => token.addr === formik.values.minor)
    if (newRight) {
      setRight(newRight);
    }
  }, [tokens.length, formik.values.minor]);

  useEffect(() => {
    const newLeft: Token | undefined = tokens.find((token) => token.addr === formik.values.major)
    if (newLeft) {
      setLeft(newLeft);
    }
  }, [tokens.length, formik.values.major]);

  useEffect(() => {
    setLeftAmount(parseFloat(String(formik.values.majorAmount)));
  }, [formik.values.majorAmount]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={cnb({ "active": false }, "form")}>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          allowTouchMove={false}
          navigation={false}
          pagination={false}
          scrollbar={false}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          <SwiperSlide>
            <Panel
              className={"widget-bg-primary"}
              header={<h5 className={"title title-normal widget-panel-title widget-text-primary"}>Swap tokens</h5>}
            >
              <SwapPicker<FormValues>
                disabled={false}
                className={"pay-swap-picker"}
                label={"You pay"}
                options={tokens.map((token, index) => ({
                  label: token.symbol,
                  title: token.name,
                  value: token.addr,
                  icon: token.logoURI.replace("png", "svg"),
                  decimals: token.decimals,
                  disabled: false
                }))}
                placeholder={"0"}
                fieldCurrency={"major"}
                fieldAmount={"majorAmount"}
              />
              <SwapPicker<FormValues>
                disabled={false}
                className={"get-swap-picker"}
                status={rateState && rateState.isLoading() ? "loading" : undefined}
                label={"You get"}
                options={pairs.length ? tokens.map((token, index) => ({
                  label: token.symbol,
                  title: token.name,
                  value: token.addr,
                  icon: token.logoURI.replace("png", "svg"),
                  decimals: token.decimals,
                  disabled: pairs.find((pair) => pair.addr === token.addr) ? false : true
                })) : []}
                placeholder={""}
                fieldCurrency={"minor"}
                fieldAmount={"minorAmount"}
                disabledInput={true}
              />

              <div
                className={`${cnb("rate", "action", "action-small", "color-faded")} widget-text-secondary`}
                onClick={() => setInvertRate(!invertRate)}
              >
                {left && right
                  ? (rate && rateState && rateState.isSuccess()
                    ? <>1&nbsp;&nbsp;{invertRate ? right?.symbol : left?.symbol} &nbsp;&nbsp;=&nbsp;&nbsp; {invertRate ? 1 / rate.rate : parseFloat(rate.rate.toFixed(11))}&nbsp;&nbsp;{invertRate ? left?.symbol : right?.symbol}</>
                    : <><LoaderDotsText />&nbsp;&nbsp;{left?.symbol} &nbsp;&nbsp;=&nbsp;&nbsp; <LoaderDotsText />&nbsp;&nbsp;{right?.symbol}</>)
                  : "Loading..."}
              </div>

              <SlideWrapper direction="next"><Button
                size="lg"
                variant="primary"
                type="button"
                className={`${cnb("button-action")} widget-button`}
                disabled={formik.isSubmitting || !rate || rateState.isLoading() || !formik.values.minorAmount}
              >
                {formik.isSubmitting && <Loader />} Review order
              </Button></SlideWrapper>
              <FlexContainer
                direction="row"
                className={cnb("fees")}
              >
                <Flex
                  grow={1}
                  shrink={1}
                  className={"color-faded widget-text-secondary"}
                >
                  Estimated fees
                </Flex>
                <Flex
                  grow={1}
                  shrink={1}
                  className={"align-right"}
                >
                  up to {rate ? <>&nbsp;&nbsp;{rate.feeStr}&nbsp;&nbsp;</> : <>&nbsp;&nbsp;<LoaderDotsText />&nbsp;&nbsp;</>} EVER
                </Flex>
              </FlexContainer>
            </Panel>
          </SwiperSlide>
          <SwiperSlide>
            <h5 className={`${cnbswap('out-of-panel-header')} title title-normal widget-text-primary`}>
              <SlideWrapper direction="prev">
                <Icon icon="arrow-left" className={`${cnb("icon-back")} widget-icons-text`} />Back
              </SlideWrapper>
            </h5>
            <Panel
              className={`${cnb("slider-slide-panel")} widget-panel`}
              header={<h5 className={"title title-normal"}>
                Swap tokens
              </h5>}
            >
              <dl className={cnb("swap-summary", "paragraph", "paragraph-normal")}>
                <FlexContainer
                  className={`${cnb("swap-summary-row")} widget-summary-row`}
                  justify="space-between"
                >
                  <dt className={`${cnb("color-faded", "paragraph")} widget-text-secondary`}>Pay</dt><dd>{formik.values.majorAmount} {left?.symbol}</dd>
                </FlexContainer>
                <FlexContainer
                  className={`${cnb("swap-summary-row")} widget-summary-row`}
                  justify="space-between"
                >
                  <dt className={cnb("color-faded", "paragraph") + " widget-text-secondary"}>Get</dt><dd>{rate && rate.expectedStr} {right?.symbol}</dd>
                </FlexContainer>
                <FlexContainer
                  className={`${cnb("swap-summary-row")} widget-summary-row`}
                  justify="space-between"
                >
                  <dt className={`${cnb("color-faded", "paragraph")} widget-text-secondary`}>Fee</dt><dd>{rate && rate.feeStr} EVER</dd>
                </FlexContainer>
              </dl>
            </Panel>
            <Panel
              collapse={true}
              className={`${cnb("slider-slide-panel")} widget-panel`}
              header={<h5 className={"title title-normal"}>
                Fees details
              </h5>}
            >
              <dl className={cnb("swap-summary", "paragraph", "paragraph-normal")}>
                <FlexContainer
                  className={`${cnb("swap-summary-row")} widget-summary-row`}
                  justify="space-between"
                >
                  <dt className={`${cnb("color-faded", "paragraph")} widget-text-secondary`}>Pay</dt><dd>{formik.values.majorAmount} {left?.symbol}</dd>
                </FlexContainer>
                <FlexContainer
                  className={`${cnb("swap-summary-row")} widget-summary-row`}
                  justify="space-between"
                >
                  <dt className={cnb("color-faded", "paragraph") + " widget-text-secondary"}>Get</dt><dd>{rate && rate.expectedStr} {right?.symbol}</dd>
                </FlexContainer>
                <FlexContainer
                  className={`${cnb("swap-summary-row")} widget-summary-row`}
                  justify="space-between"
                >
                  <dt className={`${cnb("color-faded", "paragraph")} widget-text-secondary`}>Fee</dt><dd>{rate && rate.feeStr} EVER</dd>
                </FlexContainer>
              </dl>
            </Panel>
            <Panel
              className={"widget-bg-primary"}>
              <Button
                size="lg"
                variant="primary"
                type="button"
                className={`${cnb("button-action")} widget-accent-bg widget-text-primary`}
                disabled={formik.isSubmitting}
                onClick={() => setConfirmed(true)}
              >
                {formik.isSubmitting && <Loader />} Proceed to swap
              </Button>
            </Panel>
            <ModalSheet
              show={confirmed}
              className={`${cnb("swap-confirmed")} widget-bg-primary`}
              header={<FlexContainer
                justify="space-between"
                align="center"
                style={{ flexGrow: 1 }}
              >
                <Flex className={"title title-normal widget-text-primary"}>Confirm to proceed</Flex>
                <Flex>
                  <Button
                    size="blank"
                    variant="variant"
                    onClick={() => {
                      setConfirmed(false);
                    }}
                    className={"widget-text-primary"}
                  >
                    <Icon className={"widget-icons-text"} icon={"close"} />
                  </Button>
                </Flex>
              </FlexContainer>}
            >
              <div ref={refQr} className={cnb("qrcode")}></div>

              <div className={`${cnb("qrcode-desc", "paragraph", "paragraph-small", "align-center")} widget-text-secondary`}>{`Scan this QR code, follow the link\n and confirm the swap in the app`}</div>

              <a href={url} className={cnb(cnbswap("qrcode-link"), "paragraph", "paragraph-medium", "align-center")}>Open Everlink</a>
            </ModalSheet>
          </SwiperSlide>
        </Swiper>
      </form>
    </FormikProvider>
  );
};

export default SwapForm;