import callback from "./abi";
import { Abi, AbiContract, TonClient, UtilsModule } from "@eversdk/core";

import { 
  Debot,
  Token,
  Rate,
  address
 } from "../interfaces";
import DexPair from "./abi/DexPair.abi";
import DexRoot from "./abi/DexRoot.abi";
import Utils from "./utils";

const browser = import("debot-browser");

export const Debots:Map<string, Debot> = new Map([
  ['swap', {
    title: 'swap',
    name: 'https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d',
    alias: 'mainnet',
    address: '0:fb69ca9f22e9ce54d926176ca68614644521050c77dc53d644081c51ef3a892c'
  }],
  ['fees_dex', {
    title: 'fees_dex',
    name: 'https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d',
    alias: 'mainnet',
    address: '0:5eb5713ea9b4a0f3a13bc91b282cde809636eb1e68d2fcb6427b9ad78a5a9008'
  }],
]);

const logger = console;

export default class DebotClient {
  static convert = (from: any, to: any) => (data: any) => Buffer.from(data, from).toString(to);

  static hexToUtf8 = DebotClient.convert('hex', 'utf8');
  static utf8ToHex = DebotClient.convert('utf8', 'hex');

  private static browserHandle: Promise<BigInt>;
  private static SDKClient: TonClient;

  constructor () {
    DebotClient.init();
  }
  
  static async init () {

    this.SDKClient = new TonClient({
      network: {
          // Local Evernode SE instance URL here
          endpoints: ["https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d"]
      }
    });
    DebotClient.browserHandle = (await browser).create_browser(Debots.get('swap')!.name, Debots.get('swap')!.address);
  }

  /**
   * Get JSON manifest for browser request
   * TODO proper typization for func and args prop
   **/
  private static buildManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('swap')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "chain": [],
      "abi": ${callback.abi}
    }`);
  };

  /**
   * Get trading pairs with trading metadata
   **/
  static async getTokens(): Promise<{tokens: Token[]}> {
    logger.log(`Calling getTokens...\n`);
    const manifest = DebotClient.buildManifest("invokeGetTokens", "{}");
    return await (await browser).run_browser((await DebotClient.browserHandle), manifest);
  }

  /**
   * Get trading pairs with trading metadata
   **/
  static async getPairs(tokenRoot: address): Promise<{tokens: Token[]}> {
    logger.log(`Calling getPairs...\n`);
    //const manifest = DebotClient.buildManifest("invokeGetPairs", `{"tokenRoot": "${tokenRoot}"}`);
    const manifest = DebotClient.buildManifest("invokeGetPairs", `{"tokenRoot": "${tokenRoot}"}`);
    return await (await browser).run_browser((await DebotClient.browserHandle), manifest);
  }

  /**
   * Get swap rate
   **/
  static async getRate(leftRoot: Token, rightRoot: Token, amount: number): Promise<{status: number, rate: Rate}> {
    logger.log(`Calling calcRate...\n`);
    const manifest = DebotClient.buildManifest("invokeCalcRate", `{"left": ${JSON.stringify(leftRoot)}, "right": ${JSON.stringify(rightRoot)}, "leftAmount": "${amount}"}`);
    return await (await browser).run_browser((await DebotClient.browserHandle), manifest);
  }

  /**
   * Get swap rate
   **/
  static async swap(leftRoot: address, rightRoot: address, amount: number): Promise<{status: number, rate: Rate}> {
    logger.log(`Calling invokeSwap...\n`);
    const manifest = DebotClient.buildManifest("invokeSwap", `{"left": ${leftRoot}, "right": ${rightRoot}, "leftAmount":"${amount}"}`);
    return await (await browser).run_browser((await DebotClient.browserHandle), manifest);
  }

  /**
   * Get expected pair address
   **/
   static async getExpectedPairAddress(token: address): Promise<address> {
    logger.log(`Calling getExpectedPairAddress...\n`);
    const dexRoot: address = "0:5eb5713ea9b4a0f3a13bc91b282cde809636eb1e68d2fcb6427b9ad78a5a9008";

    const [account, message] = await Promise.all([
      // Download the latest state (BOC)
      this.SDKClient.net.query_collection({
          collection: 'accounts',
          filter: { id: { eq: dexRoot } },
          result: 'boc'
      })
      .then(({ result }) => result[0].boc)
      .catch(() => {
          throw Error(`Failed to fetch account data`)
      }),

      this.SDKClient.abi.encode_message({
          abi: {
            type: 'Contract',
            value: DexRoot.abi as AbiContract,
          },
          address: dexRoot,
          call_set: {
              function_name: 'getExpectedPairAddress',
              input: {
                answerId: 0,
                left_root: "0:a49cd4e158a9a15555e624759e2e4e766d22600b7800d891e46f9291f044a93d",  // WEVER root
                right_root: token
              }
          },
          signer: { type: 'None' }
      }).then(({ message }) => message)
    ]);
    
    const response =  await this.SDKClient.tvm.run_tvm({ message, account, abi: {
      type: 'Contract',
      value: DexRoot.abi as AbiContract,
    }});

    const output = response.decoded?.output;
    
    if (Object.keys(output).length) return output[Object.keys(output)[0]];

    throw new Error("No pair address was fetched");
  }

  /**
   * Get expected pair address
   **/
   static async getExpectedExchangeRate(pairAddress: address, token: address, amount__: number | string, decimals__: number): Promise<any> {
    logger.log(`Calling getExpectedExchangeRate...\n`);
    logger.log(decimals__);

    // const parseFloatToDecimals = (amount: number | string) => {
    //   const decimals = String(amount).length - String(amount).indexOf(".") - 1;
    //   return [Number(amount) * 10**decimals, decimals]
    // }
    // const [amount, decimals] = parseFloatToDecimals(amount__);

    // console.log(decimals);
    // console.log(String(BigInt(amount) * BigInt(10 ** (decimals__ - decimals))));

    const [account, message] = await Promise.all([
      // Download the latest state (BOC)
      this.SDKClient.net.query_collection({
          collection: 'accounts',
          filter: { id: { eq: pairAddress } },
          result: 'boc'
      })
      .then(({ result }) => result[0].boc)
      .catch(() => {
          throw Error(`Failed to fetch account data`)
      }),

      this.SDKClient.abi.encode_message({
          abi: {
            type: 'Contract',
            value: DexPair.abi as AbiContract,
          },
          address: pairAddress,
          call_set: {
              function_name: 'expectedExchange',
              input: {
                answerId: 0,
                spent_token_root: token,
                amount: String(Math.floor(Number(amount__) * 10**6))
              }
          },
          signer: { type: 'None' }
      }).then(({ message }) => message)
    ]);

    const result =  await this.SDKClient.tvm.run_tvm({ message, account, abi: {
      type: 'Contract',
      value: DexPair.abi as AbiContract,
    }});
    console.log(result)

    return result
  }
}

(async () => {
  new DebotClient();
})()

