import {
  LOAD_TOKENS,
  SET_TOKENS,
  LOAD_PAIRS,
  SET_PAIRS,
  LOAD_RATE,
  SET_RATE
} from "./constants";

import {
  Token,
  Rate
} from "../../interfaces";

export const loadPairs = () =>
  ({
    type: LOAD_PAIRS,
    payload: [] as Token[]
  } as const);

export const setPairs = (value: Token[]) =>
  ({
    type: SET_PAIRS,
    payload: value,
  } as const);

export const loadTokens = () =>
  ({
    type: LOAD_TOKENS,
    payload: [] as Token[]
  } as const);

export const setTokens = (value: Token[]) =>
  ({
    type: SET_TOKENS,
    payload: value,
  } as const);

export const loadRate = () =>
  ({
    type: LOAD_RATE,
    payload: [] as Token[]
  } as const);

export const setRate = (value: Rate) =>
  ({
    type: SET_RATE,
    payload: value,
  } as const);
