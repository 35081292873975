import { ReactNode } from "react";
import styles from "./Widget.module.scss";
import classnames from "classnames/bind";
const cnb = classnames.bind(styles);

export const Widget = ({className, children}: {className?: string, children?: ReactNode}) => {
  return (
    <div className={cnb("widget", className)}>
      {children}
    </div>
  );
};

export default Widget;
