import { Dispatch, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionTypes, RootState } from "../types";
import { store } from "../redux/store/configure-store";

import DebotClient from "../client/client";

import {
  loadTokens,
  setTokens,

  loadRate,
  setRate
} from "../redux/actions";

import {
  Token,
  Rate
 } from "../interfaces";
import { ReactNode } from "react-markdown";
import { PromiseState } from "../utils/store/PromiseUtils";

export interface IUseGetEver {
    rate: Rate | undefined,
    rateState: PromiseState,
}

export const useGetEver = (): IUseGetEver => {
  const [descriptor, setDescriptor] = useState<ReturnType<typeof setInterval>>();
  const dispatch = useDispatch();

  const { 
    tokens: {data: tokens},
    rate: {data: rate, promiseState: rateState},
    pairs: {data: pairs},
  } = useSelector((state: RootState) => state.swap);

  const [ left, setLeft ] = useState<Token>();
  const [ right, setRight ] = useState<Token>();
  const [ leftAmount, setLeftAmount ] = useState<number>(0);

  useEffect(() => {
    dispatch(loadTokens());
    DebotClient.getTokens()
    .then((value) => {
      console.log(value.tokens);
      dispatch(setTokens(value.tokens));
    });
  }, []);


  useEffect(() => {
    if (tokens.length) {
      const right = tokens.find((token: Token) => token.symbol === "USDT");
      const left = tokens.find((token: Token) => token.symbol === "WEVER");
      if (left && right) {
        dispatch(loadRate());
        DebotClient.getRate(left, right, 1 * (10**left.decimals))
        .then((value) => {
          console.log(value.rate);
          if (value.rate)
          dispatch(setRate({
            ...value.rate,
            rate: parseFloat(String(value.rate.rate)) / (10**right.decimals)
          }));
        });
        setDescriptor(setInterval(() => {
          dispatch(loadRate());
          DebotClient.getRate(left, right, 1 * (10**left.decimals))
          .then((value) => {
            console.log(value.rate);
            if (value.rate)
            dispatch(setRate({
              ...value.rate,
              rate: parseFloat(String(value.rate.rate)) / (10**right.decimals)
            }));
          });
        }, 30000));
      }
    }
    return () => {
      if (descriptor) clearInterval(descriptor);
      setDescriptor(undefined)
    }
  }, [tokens]);

  return useMemo(
    () => ({
      rate: rate,
      rateState: rateState,
    }),
    [tokens, pairs, left, right, rate, rateState],
  );
};
