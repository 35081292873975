import {
  SET_EVER_ADDRESS,
  SET_EVER_TYPE,
} from "./constants";

import {
  address,
} from "../../types";

export const setEverAddress = (value: address) =>
  ({
    type: SET_EVER_ADDRESS,
    payload: value,
  } as const);

export const setEverType = (value: string) =>
  ({
    type: SET_EVER_TYPE,
    payload: value,
  } as const);

