import callback from "./abi";
import { Credit, Tokens, EverscaleTokens, ChainEndpoints } from "./tokens";
import CreditFactory from "./abi";
import { Vault } from "./abi/Vault.abi";
import { EthereumEventConfiguration } from "./abi/EthereumEventConfiguration.abi";
import { ERC20 } from "./abi/ERC20.abi";

import { 
  Debot,
  Token,
  Rate,
  address
 } from "../interfaces";
import { Console } from "console";
//import * as browser from "debot-browser"; 
const browser = import("debot-browser");

export const Debots:Map<string, Debot> = new Map([
  ['swap', {
    title: 'swap',
    name: 'main.ton.dev',
    alias: 'mainnet',
    address: '0:fb69ca9f22e9ce54d926176ca68614644521050c77dc53d644081c51ef3a892c'
  }]
]);

const logger = console;

export default class ExtensionHelper {

  private static networkTokens: {[key: string]: Array<any>} = {};
  private static networks: any = [];
  private static everscaleTokensList: any;
  static abi: any = {};
  static chainEndpoints: any = [];
  static recipientSurf: address;
  static creditAddress: address;

  constructor () {
    ExtensionHelper.init();
  }
  
  static async init () {
    this.abi = {
      Vault: Vault.abi,
      CreditFactory: CreditFactory.abi,
      EthereumEventConfiguration: EthereumEventConfiguration.abi,
      ERC20: ERC20.abi,
    }
    // fetch('https://raw.githubusercontent.com/broxus/bridge-assets/master/main.json'
    //   ,{
    //     mode: 'no-cors',
    //     headers : { 
    //       'Content-Type': 'application/json',
    //       'Accept': 'application/json'
    //     }
    //   }
    //   )
    //   .then((response) => {
    //     // debugger;
    //     console.log(response);
    //   });

    // fetch('https://raw.githubusercontent.com/broxus/everscale-assets-upgrade/master/main.json'
    //   ,{
    //     headers : { 
    //       'Content-Type': 'application/json',
    //       'Accept': 'application/json'
    //     }
    //   }
    //   )
    //   .then((response) => {
    //     console.log(response);
    //   });

    this.everscaleTokensList = EverscaleTokens;

    for (const tokenId in Tokens.token) {
      if (Object.prototype.hasOwnProperty.call(Tokens.token, tokenId)) {
        const token = Tokens.token[tokenId as keyof typeof Tokens.token];
        for (let i = 0; i < token.vaults.length; i++) {
          const vault = token.vaults[i];
          if (vault.depositType === "credit") {
            if (!this.networks.includes(vault.chainId)) {
              this.networks.push(vault.chainId);
            }
            if (!this.networkTokens[vault.chainId]) this.networkTokens[vault.chainId] = [];
            this.networkTokens[vault.chainId].push({...vault, tokenId: tokenId});
          }
        }
      }
    }

    this.creditAddress = Credit.creditors[0].address;
    this.chainEndpoints = ChainEndpoints;
    this.recipientSurf = "0:534e54357c1bfdf56b22e91cb820ab7fa834cc5fd7833e1996b40f3ddd7a33a0";
  }

  /**
   * Get JSON manifest for browser request
   * TODO proper typization for func and args prop
   **/
  public static getNetworks (): Array<any> {
    return this.networks;
  };
  public static getEverscaleTokens (): any {
    return this.everscaleTokensList;
  };
  public static getNetworkTokens (chainId?: string | number): any {
    if (chainId)
      return this.networkTokens[chainId];
    return this.networkTokens;
  };
}

(async () => {
  new ExtensionHelper();
})()

