import React, { useState, useEffect, useRef, ReactNode } from "react";
import { Dropdown as DropdownBs, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from './Dropdown.module.scss';
import { Button, IButton, Icon } from "..";
import classnames from "classnames/bind";

const cnb = classnames.bind(styles);

interface IOption {
  to?: string,
  href?: string,
  title: string,
  disabled?: boolean,
  default?: boolean,
  hidden?: boolean,
}

interface IDropdown extends IButton {
  options: IOption[],
  title: string,
  placeholder: string
  active?: boolean,
}

export const Dropdown = React.forwardRef<HTMLElement,IDropdown>((props, ref) => {
  const {
    title,
    placeholder,
    options,
    variant = "regular",
    active,
    disabled,
    className
  } = props;
  
  const [show, setShow] = useState(false);
  const showDropdown = () => {
      setShow(!show);
  }
  const hideDropdown = () => {
      setShow(false);
  }

  return <div className={cnb("dropdown", className)}>
    <DropdownBs.Toggle variant="success" disabled={disabled}>
      {title ? title : (placeholder)}
    </DropdownBs.Toggle>

    <DropdownBs.Menu
      show={true}
    >
      {options.map((option, index) => {
        return option.to 
          ? <DropdownBs.Item as={Link} key={index} to="#/action-1">{option.title}</DropdownBs.Item>
          : <DropdownBs.Item href={option.href} key={index}>{option.title}</DropdownBs.Item>
      })}
    </DropdownBs.Menu>
  </div>
});
