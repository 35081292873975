import React, { useState, useEffect, useRef, ReactNode, useMemo, useCallback } from "react";
// import ReactSelect from 'react-select';
// import { CommonProps, GroupBase } from 'react-select';
// import OptionType from 'react-select';
import styles from './SwapPicker.module.scss';
import { useFormikContext, Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { Icon, InputComponent as Input, Panel, Flex, FlexContainer, Button} from "..";
import { InputNumber, OptionProps, PickerProps } from "./";
import { useKeyUp } from "../../utils";
import { FormGroupProps } from "../../interfaces";
import classnames from "classnames/bind";
import { LoaderDots } from "../";

const cnb = classnames.bind(styles);


const InputFC = ({ field, form, ...props }: FieldProps) => {
  return <input {...field} {...props} />;
};

export const SwapPicker = <FormValues, >({
  label,
  disabled,
  disabledInput,
  placeholder,
  options,
  status,
  fieldCurrency,
  fieldAmount,
  className
} : FormGroupProps & PickerProps & {
  status?: "error" | "success" | "loading" | "validate" | undefined,
  placeholder: string,
  disabledInput?: boolean,
  fieldCurrency: keyof FormValues,
  fieldAmount: keyof FormValues
}) => {
  const {
    values,
    errors,
    setFieldValue,
  } : {
    values: FormValues,
    errors: FormikErrors<FormValues>,
    handleChange: any,
    handleBlur: any,
    touched: FormikTouched<FormValues>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  } = useFormikContext<FormValues>();

  const currency = values[fieldCurrency];
  const amount = values[fieldAmount];

  const [active, setActive] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<OptionProps>(options[0]);
  const [dropdownOptions, setDropdownOptions] = useState<ReactNode>(<></>);
  const [filter, setFilter] = useState<string>("");

  const refPicker = useRef<HTMLDivElement>(null);
  const refButton = useRef<HTMLDivElement>(null);
  const refFilterInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSelectedOption(options.find((option, index) => option.value === String(currency)) || options[0]);
  }, [currency, options]);

  const handleOptionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
    setSelectedOption(options.find((option, index) => option.value === event.currentTarget.getAttribute("data-value") as string) || options[0]);
    setFieldValue(
      fieldCurrency as string,
      event.currentTarget.getAttribute("data-value") as string || ''
    );
    handleDropdownClick(false);
  };

  const handleKeyUp = useCallback((event: any) => {
    if (event.keyCode === 27) {
      if (filter) {
        setFilter("");
      } else {
        setActive(false)
      }
    }
    event.stopPropagation();
  }, [filter]);

  useKeyUp(refPicker, handleKeyUp, [filter]);

  useEffect(() => {
    const filteredOptions = options.filter((option) => 
      option.label.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
      option.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
    setDropdownOptions(filteredOptions.length ? [
     options
     .filter((option) => 
       option.label.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
       option.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
     ).map((option, index) => 
      <div 
        key={index}
        data-value={option.value}
        className={`${styles.option} ${option.disabled ? styles['option-disabled'] : ''}`}
        onClick={option.disabled ? undefined : handleOptionClick}>
          <div className={styles['option-icon']}><img src={option.icon}/></div>
          <div>
            <div className={cnb("action", "action-normal") + " widget-text-secondary"}>{option.title}</div>
            <div className={cnb("paragraph", "paragraph-small", "color-faded")+ " widget-text-primary"}>{option.label}</div>
          </div>
      </div>
  )] : <div className={cnb("option", "option-placeholder")}>
      <div>
        <div className={cnb("action", "action-normal")}>We can’t find this token</div>
        <div className={cnb("paragraph", "paragraph-small", "color-faded")}>Try changing the request</div>
      </div>
    </div>);
  }, [currency, selectedOption, filter]);

  const handleDropdownClick = (value: boolean) => setActive(value);

  const handleChangeFilter = (event:React.FormEvent<HTMLInputElement>) => {
    setFilter(event.currentTarget.value);
  }

  // useOutsideClick(refPicker, () => handleDropdownClick(false));

  const hasLabel = !errors[fieldCurrency] && !errors[fieldAmount] && !!currency && !!amount && !active;
  const hasError = errors[fieldCurrency] || errors[fieldAmount];

  return <>
  
  <div ref={refPicker} className={`${styles['swap-picker']} ${disabled && styles['picker-disabled']} ${className}`}>
    <Panel
      className={cnb('dropdown-menu', {"dropdown-menu-active": active}) + " widget-bg-primary"}
      header={<FlexContainer
        justify="space-between"
        align="center"
        style={{flexGrow: 1}}
      >
        <Flex className={"title title-normal widget-text-primary"}>{label}</Flex>
        <Flex>
          <Button
            size="medium"
            className={styles["close-button"]}
            color="transparent"
            type="button"
            onClick={() => handleDropdownClick(false)}
            iconLeft={{
              animation: "none",
              icon: <Icon className={"widget-icons-text"} icon={"close"} />
            }}
          >
          </Button>
        </Flex>
      </FlexContainer>}
    >
      <Input<{[key: string]: string}>
        onKeyUp={handleKeyUp}
        name={"filter-" + label}
        value={filter}
        onChange={handleChangeFilter}
        composition="substitute"
        icon={"search"}
        className={`${styles['dropdown-menu-header']} widget-input`}
        action={filter && <Button color={"transparent"} className={cnb("clear-button", {"active": filter})} type="button" onClick={() => setFilter("")}><Icon icon="close-alt" className={"widget-icons-text"} /></Button>}
        placeholder="Search..."
      />
      <div className={cnb('dropdown-menu-body')}>
        <div className={cnb('dropdown-menu-list')}>
          <div>
            {dropdownOptions}
          </div>
        </div>
      </div>
    </Panel>
    <div className={`form-group ${styles.group}`}>
      <div ref={refButton} onClick={() => handleDropdownClick(true)} className={styles['dropdown-button']}>
        <div className={cnb('dropdown-button-body')}>
          <span className={"paragraph paragraph-small color-faded widget-text-secondary"}>{label}</span>
          <span className={cnb("action", "action-normal", "title")}>
            {selectedOption ? selectedOption.label : <LoaderDots />} <Icon className={styles['dropdown-button-chevron'] + " widget-icons-text"} icon={"chevron-down"} />
          </span>
        </div>
      </div>
      <InputNumber<FormValues>
        name={fieldAmount}
        className={`form-control ${styles.input} ${disabledInput ? styles.disabled : ""} widget-input`}
        placeholder={status === "loading" ? "Loading..." : placeholder}
        disabled={disabledInput}
        composition="substitute"
        autoComplete="off"
      />
    </div>
    <Field as="select"
      hidden={true}
      name={fieldCurrency}
      className={styles.select}
      placeholder={placeholder}>
      {
        options.map((option, index) => <option key={index} value={option.value}>{option.title}</option>)
      }
    </Field>
  </div>
  </>;
};
