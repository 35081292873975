import { ReactNode } from "react";
import styles from './FormControl.module.scss';
import classnames from "classnames/bind";
import { Flex, FlexContainer } from "../Flex";
const cnb = classnames.bind(styles);

export interface IFormControl {
  title?: string,
  className?: string,
  caption?: string,
  captionSecondary?: string,
  validation?: string,
  status?: "error" | "success" | "loading" | "validate" | undefined,
  children?: ReactNode,
}

export const FormControl = ({
  title,
  caption,
  captionSecondary,
  status,
  className,
  children,
  ...props
} : IFormControl) => {
  return (  
    <div className={cnb("form-control", "form-control-status-" + status ,className)}>
      {title && <div className={cnb("title", "title-medium", "form-control-title")}>
        {title}
      </div>}
      {children}
      <FlexContainer
        direction="row"
        justify="space-between"
        align="center"
        className={cnb("form-control-caption", "paragraph", "paragraph-small")}
      >
        {caption && <Flex>
          {caption}
        </Flex>}
        {captionSecondary && <Flex>
          {captionSecondary}
        </Flex>}
      </FlexContainer>
    </div>
  );
};