import React, { useEffect, ReactNode, useRef, useState, useMemo, InputHTMLAttributes, ChangeEvent } from "react";
//import React, { useState, useEffect, useRef, ReactNode, InputHTMLAttributes } from "react";
// import ReactSelect from 'react-select';
// import { CommonProps, GroupBase } from 'react-select';
// import OptionType from 'react-select';
import styles from './Input.module.scss';
import { templateParser, templateFormatter, parseDigit } from 'input-format';
import ReactInput from 'input-format/react';

import { Icon } from "..";
// /import useOutsideClick from "../../utils/outsideClickCallback";
import { IFormControl } from "../../components";
import { Button, FormControl, FlexContainer } from "../../components";
import classnames from "classnames/bind";

const cnb = classnames.bind(styles);

const PHONE_TEMPLATE = '(xxx) xxx-xxxx';
const CARD_TEMPLATE = 'xxxx xxxx xxxx xxxx';
const CARD_DATE_TEMPLATE = 'xx / xx';

export interface IInput<FormValues> extends Omit<InputHTMLAttributes<HTMLInputElement>, "name" | "onSelect">{
  name: keyof FormValues,
  label?: string,
  autoComplete?: "on" | "off" | undefined,
  autoFocus?: boolean | undefined,
  icon?: string,
  status?: "error" | "success" | "loading" | "validate" | undefined,
  composition?: "substitute" | "slide" | "duplet",
  units?: ReactNode,
  clear?: boolean,
  validation?: string | undefined,
  action?: ReactNode,
  mask?: string
}

export const InputComponent = <FormValues, >({
  label,
  placeholder,
  clear,
  composition,
  validation,
  icon,
  units,
  action,
  disabled,
  autoComplete,
  type,

  name,
  value,
  className,

  onChange,
  onFocus,
  onBlur,

  mask,
  ...props
} : IInput<FormValues>) => {
  const [focused, setFocused] = useState<boolean>(false);

  const ref = useRef<HTMLInputElement>(null);
  const refHidden = useRef<HTMLInputElement>(null);
  const refIcon = useRef<HTMLDivElement>(null);
  const refUnits = useRef<HTMLDivElement>(null);
  const refAction = useRef<HTMLDivElement>(null);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true);
    if (onFocus) onFocus(e);
  }
  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(false);
    if (onBlur) onBlur(e);
  }

  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current.addEventListener("focus", (e: FocusEvent) => console.log("ref-focus"));
  //     ref.current.addEventListener("blur", (e: FocusEvent) => console.log("ref-blur"));
  //   }
  //   return () => {
  //     if (ref.current) {
  //       ref.current.removeEventListener("focus",  (e) => console.log("ref-focus"));
  //       ref.current.removeEventListener("blur",  (e) => console.log("ref-blur"));
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (Boolean(units && refUnits && ref && ref.current && ref.current.style)) {
      ref.current!.style!.paddingRight! = (Number(refUnits.current?.offsetWidth) + 8) + "px";
    }
    if (Boolean(action && refAction && ref && ref.current && ref.current.style)) {
      ref.current!.style!.paddingRight! = (Number(refAction.current?.offsetWidth) + 8) + "px";
    }
  }, [units, value, action, refAction, refUnits]);

  return <>    
      <div className={cnb("input-group", {"input-group-focused": focused}, {"input-group-active": Boolean(value)}, className, {"input-has-clear": clear}, {"input-disabled": disabled}, {"input-validation": Boolean(validation)}, {"input-icon": icon}, composition ? "input-" + composition : undefined)}>
        {icon && <div ref={refIcon} className={cnb("input-icon")}>
          <Icon icon={icon} />
        </div>}
        {clear && !disabled && <div
          className={cnb("input-clear")}
        >
          <Button
            onClick={() => {
              if (refHidden.current) {
                const event_ = new Event('input', { bubbles: true});
                refHidden.current.value = String("");
                refHidden.current.dispatchEvent(event_);
                //if(onChange) onChange(event_ as unknown as ChangeEvent<HTMLInputElement>);
              }
            }}
          >
            <Icon icon={"close-alt"} />
          </Button>
        </div>}
        {(() => {
          switch (composition) {
            case "substitute":
              return mask
                ? <>
                  <input
                    ref={refHidden}
                    name={String(name)}
                    disabled={disabled}
                    hidden={true}
                    onInput={onChange}
                    {...props}
                  />
                  <ReactInput
                    ref={ref}
                    size={1}
                    type={type}
                    disabled={disabled}
                    className={cnb("input-component")}
                    placeholder={placeholder}
                    autoComplete={autoComplete || "on"}
                    value={value ? String(value) : undefined}
                    // TODO Why Formik onChange callback's not working? ReactInput's param is a value not an event
                    onChange={(value: string) => {
                      if (refHidden.current) {
                        const event_ = new Event('input', { bubbles: true});
                        refHidden.current.value = String(value);
                        refHidden.current.dispatchEvent(event_);
                      }
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    parse={templateParser(mask, parseDigit)}
                    format={templateFormatter(mask)}
                  />
                </>
                : 
                <>
                  <input
                    ref={refHidden}
                    name={String(name)}
                    disabled={disabled}
                    hidden={true}
                    onInput={onChange}
                    {...props}
                  />
                  <input
                    size={1}
                    type={type}
                    ref={ref}
                    name={String(name)}
                    value={value}
                    disabled={disabled}
                    className={cnb("input-component")}
                    placeholder={placeholder}
                    autoComplete={autoComplete || "on"}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (refHidden.current) {
                        const event_ = new Event('input', { bubbles: true});
                        refHidden.current.value = String(event.target.value);
                        refHidden.current.dispatchEvent(event_);
                      }
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    {...props}
                  />
                </>
            
            case "slide":
              return <>
                <label className={cnb("input-label", "paragraph", "paragraph-normal")}>{label}</label>
                {mask
                ? <>
                <input
                  ref={refHidden}
                  name={String(name)}
                  disabled={disabled}
                  hidden={true}
                  onInput={onChange}
                  {...props}
                />
                <ReactInput
                  ref={ref}
                  size={1}
                  type={type}
                  disabled={disabled}
                  className={cnb("input-component")}
                  placeholder={placeholder}
                  autoComplete={autoComplete || "on"}
                  value={value ? String(value) : undefined}
                  // TODO Why Formik onChange callback's not working? ReactInput's param is a value not an event
                  onChange={(value: string) => {
                    if (refHidden.current) {
                      const event_ = new Event('input', { bubbles: true});
                      refHidden.current.value = String(value);
                      refHidden.current.dispatchEvent(event_);
                    }
                  }}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  parse={templateParser(mask, parseDigit)}
                  format={templateFormatter(mask)}
                />
              </>
                :
                <>
                  <input
                    ref={refHidden}
                    name={String(name)}
                    disabled={disabled}
                    hidden={true}
                    onInput={onChange}
                    {...props}
                  />
                  <input
                    ref={ref}
                    size={1}
                    type={type}
                    name={String(name)}
                    value={value}
                    disabled={disabled}
                    className={cnb("input-component")}
                    placeholder={placeholder}
                    autoComplete={autoComplete || "on"}
                    onChange={onChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </>}
              </>

            case "duplet":
              return <FlexContainer
                direction="column"
                className={cnb("flex-container")}
              >
              <label className={cnb("input-label", "paragraph", "paragraph-normal")}>{label}</label>
                
              {/* {format && parse */}
              {mask
                ? <>
                <input
                  ref={refHidden}
                  name={String(name)}
                  disabled={disabled}
                  hidden={true}
                  onInput={onChange}
                  {...props}
                />
                <ReactInput
                  ref={ref}
                  size={1}
                  type={type}
                  disabled={disabled}
                  className={cnb("input-component")}
                  placeholder={placeholder}
                  autoComplete={autoComplete || "on"}
                  value={value ? String(value) : undefined}
                  // TODO Why Formik onChange callback's not working? ReactInput's param is a value not an event
                  onChange={(value: string) => {
                    if (refHidden.current) {
                      const event_ = new Event('input', { bubbles: true});
                      refHidden.current.value = String(value);
                      refHidden.current.dispatchEvent(event_);
                    }
                  }}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  parse={templateParser(mask, parseDigit)}
                  format={templateFormatter(mask)}
                />
              </>
                : 
                <>
                  <input
                    ref={refHidden}
                    name={String(name)}
                    disabled={disabled}
                    hidden={true}
                    onInput={onChange}
                    {...props}
                  />
                  <input
                    ref={ref}
                    size={1}
                    type={type}
                    name={String(name)}
                    value={value}
                    disabled={disabled}
                    className={cnb("input-component")}
                    placeholder={placeholder}
                    autoComplete={autoComplete || "on"}
                    onChange={onChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </>}
              </FlexContainer>

            default:
              break;
          }
        })()}
        {units && <div ref={refUnits} className={cnb("input-units", "paragraph", "paragraph-normal")}>
          {units}
        </div>}
        {action && <div ref={refAction} className={cnb("input-action")}>
          {action}
        </div>}
      </div>
  </>;
};

export const InputNumberComponent = <FormValues, >({onChange, ...props} : IInput<FormValues>) => {
  const [value, setValue] = useState<number>(Number(props.defaultValue));
  const regExp = /^\-?[0-9]+\.?[0-9]*$/;
  const regExpTrim = /^0+[0-9]+\.?[0-9]*$/;
  return <InputComponent
    {...props}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
      const event = e.nativeEvent as InputEvent;
      if (event.inputType === "deleteContentBackward") {
        setValue(parseFloat(e.target.value as string));
      }
      if (event.inputType === "insertText" || event.inputType === "insertFromPaste") {
        if (!(e.target.value.match(regExp) && e.target.value.match(regExp)?.length)) {
          e.currentTarget.value = String(value);
        } else {
          if (e.target.value.match(regExpTrim) && e.target.value.match(regExpTrim)?.length) {
            e.currentTarget.value = String(parseFloat(e.target.value as string));
          }
          setValue(parseFloat(e.target.value as string));
        }
      }
      if (onChange) onChange(e);
    }}/>
}


export const Input = <FormValues, >({
  title,
  label,
  type = "text",
  placeholder,
  caption,
  captionSecondary,
  clear,
  composition,
  icon,
  units,
  validation,
  status,
  disabled,
  name,
  className,
  autoComplete,
  onChange,
  onFocus,
  onBlur,
  action,
  ...props
} : IFormControl & IInput<FormValues> & {
  type?: "text" | "number" | "password"
}) => {

  return <FormControl
    title={title}
    status={validation ? "validate" : undefined}
    caption={Boolean(validation) ? validation : caption}
    captionSecondary={captionSecondary}
  >
    {(type === "text" || type === "password") && <InputComponent
      name={String(name)}
      label={label}
      placeholder={placeholder}
      clear={clear}
      composition={composition}
      icon={icon}
      units={units}
      action={action}
      autoComplete={autoComplete}
      validation={validation}
      type={type}

      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}

      disabled={disabled}
      className={className}
      
      {...props}
    />}
    {type === "number" && <InputNumberComponent
      name={String(name)}
      label={label}
      placeholder={placeholder}
      clear={clear}
      composition={composition}
      icon={icon}
      units={units}
      action={action}
      // status={status}
      autoComplete={autoComplete}
      validation={validation}

      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}

      disabled={disabled}
      className={className}
      
      {...props}
    />}
  </FormControl>;
};

