import { useWidgetParams } from '../../../controllers/useWidgetParams';
import { FlexBuyForm } from "../../../layouts/forms/flex/FlexBuyForm";
import { WidgetColors } from '../../../types';

const Buy = () => {
  let widgetColors: WidgetColors = useWidgetParams();

  return (
    <FlexBuyForm  widgetColors={widgetColors} />
  );
};

export default Buy;
