import React, { useEffect, useState, ReactNode } from "react";
import { useFormik, Field, FormikProvider } from 'formik';
import BigNumber from 'bignumber.js';
import { TonClient, abiContract, signerNone, FieldAggregation } from '@eversdk/core';
import {
  addABI,
  decodeLogs,
  keepNonDecodedLogs,
} from 'abi-decoder';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/scss'; // core Swiper
import 'swiper/scss/pagination'; // Pagination module

import "swiper/scss/effect-fade";
import { EffectFade } from 'swiper';
import { Swiper as SwiperClass } from 'swiper/types';


import * as Yup from 'yup';
import { address } from "../../interfaces";
import ExtensionHelper from "../../client/extension";

import classnames from "classnames/bind";
import { WidgetColors } from '../../types';
import { Button, Panel, Select, FlexContainer, Flex, Icon, LoaderThreeDots, LoaderDotsText, FormControl, ISelectOption, InputSelect } from "../../components";

import Web3 from "web3";
import { Input } from "../../components";
import { MetaMask } from "../../patterns/MetaMask";
import { Status } from "../../components";
import { useConnect } from "../../controllers/useConnect";
import DebotClient from "../../client/client";
import styles from './Form.module.scss';
import stylesTransfer from './TransferForm.module.scss';

import type { MetaMaskInpageProvider } from "@metamask/providers";
import Utils from "../../client/utils";
import { floatDecimals } from "../../utils";

const cnb = classnames.bind(styles);
const cntr = classnames.bind(stylesTransfer);

const chainList = [{
  chainID: 1,
  disabled: false,
  name: "Ethereum Mainnet",
  title: "Ethereum",
  currency: "ETH"
}, {
  chainID: 56,
  disabled: false,
  name: "Binance Smart Chain Mainnet",
  title: "Binance Smart Chain",
  currency: "BNB"
}, {
  chainID: 137,
  disabled: true,
  name: "Polygon Mainnet",
  title: "Polygon",
  currency: "MATIC"
}, {
  chainID: 250,
  disabled: true,
  name: "Fantom Opera",
  title: "Fantom",
  currency: "FTM"
},];

interface FormValues {
  amount: string;
  token: address;
  wallet: string;
  chain: string;
  address: string;
}

// const Loader = () => (
//   <svg className={styles.spinner}>
//     <circle cx="20" cy="20" r="12"></circle>
//   </svg>
// );

const SlideWrapper = ({direction, children, className}: {direction: "next" | "prev" | undefined, children: ReactNode, className?: string}) => {
  const swiper = useSwiper();
  return (
    <div className={cnb("swiper-button-wrapper", className)} onClick={direction ? () => direction === "prev" ? swiper.slidePrev() : swiper.slideNext() : undefined}>{children}</div>
  );
}

type SwapFormProps = {
  widgetColors?: WidgetColors;
}

export const TransferForm = ({ widgetColors }: SwapFormProps) => {
  // swiper data
  const [swiper, setSwiper] = useState<SwiperClass>(useSwiper());
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const [accounts, setAccounts] = useState<any>();
  const [ethereum, setEthereumProvider] = useState<MetaMaskInpageProvider>();
  const [tokensInCurrentNetwork, setTokensInCurrentNetwork] = useState<any>(false);
  const [isEthereumConnected, setEtheriumConnected] = useState<boolean>(false);
  const [isTransactionComplete, setIsTransactionComplete] = useState<boolean>(false);
  const [transactionStatusCode, setTransactionStatusCode] = useState<number>(0);
  const [transactionError, setTransactionError] = useState<{
    code: string,
    message: string,
  } | undefined>();
  const [transactionBlockCounter, setTransactionBlockCounter] = useState<{
    total: number,
    current: number
  } | undefined>();


  const [surfBalance, setSurfBalance] = useState<number>();
  const [surfOldBalance, setSurfOldBalance] = useState<number>();
  const [pairAddress, setPairAddress] = useState<address>();
  const [transactionReceipt, setTransactionReceipt] = useState<address>("");
  const [expectedExchangeRate, setExpectedExchangeRate] = useState<any>();
  const [token, setToken] = useState<address>();
  const [tokenMeta, setTokenMeta] = useState<any>();
  const [chainMeta, setChainMeta] = useState<any>();

  const { address: everAddress, type } = useConnect();

  const client = new TonClient({
    network: {
        // Local Evernode SE instance URL here
        endpoints: ["https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d"]
    }
  });
  const aggregateTransactions = (
    wallet: string,
    fields?: FieldAggregation[],
  ) => {
      return client.net.aggregate_collection({collection: 'transactions', filter: {account_addr: {eq: wallet}}, fields});
  }

  const getBalance = (
    wallet: string,
    fields?: FieldAggregation[],
  ) => {
      return client.net.aggregate_collection({collection: 'accounts', filter: {code_hash: {in: wallet}}, fields: [{
        field: "balance",
        fn: "SUM"
      } as FieldAggregation]});
  }

     // тут иконки есть для токенов everscale token list https://raw.githubusercontent.com/broxus/everscale-assets-upgrade/master/main.json
    // и понять адреса рутов для tip3 в everscale
    // Адреса vault необходимо брать из https://raw.githubusercontent.com/broxus/bridge-assets/master/main.json (те для которых "depositType" == "credit")
    // если json меняется то все пропало, броксуус что-то поменял.
    /*USDT
    {
      "chainId": "56",
      "vault": "0x5d767d4e250b5c8640cb2bf7e7cd3acaeb7768e1",
      "ethereumConfiguration": "0:79c92057392371647e3578c93650a5692aa844f427af3a9c92d204c911c708ef",
      "depositType": "credit"
    },*/

    // const vaultAddr_ = '0x5d767d4e250b5c8640cb2bf7e7cd3acaeb7768e1';
    // const ethereumConfiguration_ = "0:79c92057392371647e3578c93650a5692aa844f427af3a9c92d204c911c708ef";

    // // aдрес нашего контракта который берет 0.5% он константа

    // //адрес юзера из метамаска
    // const myAddress_ = '0x833d7C0956B2E01Ff7B26f90e767736aF40dD1c8'
    // //приватник от метмаска, на сайте он не нужен. это для автоподписи в node js
    // const privateKey_ = '';
    // //адрес юзера из эвервалета
    // const myEverAddress_ = '0:9fc2d550d61581b80d9000660ecd1d026e5fe7318eb08f1773a3542193386943'


  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Nice try 😄')
      .required('')
      .positive('Nice try 😄')
      .min(0, 'Minimum 0')
      .max(1000, 'Maximum 1000'),
      //.matches(/^[0-9]+\.?[0-9]*$/, "Not a number"),
    token: Yup.string()
      .typeError('Nice try 😄')
      .matches(/^0:[a-f0-9]{64,64}$/, "Doesn't look like a valid token address")
      .required(''),
    address: Yup.string()
      .typeError('Nice try 😄')
      .required(''),
    chain: Yup.string()
      .typeError('Nice try 😄')
      .required(''),
    wallet: Yup.string()
      .matches(/^0:[a-f0-9]{64,64}$/, "Doesn't look like a valid EVER wallet address")
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      amount: "",
      chain: ethereum && ethereum.networkVersion && accounts ? ethereum.networkVersion : "",
      token: tokensInCurrentNetwork.length ? ExtensionHelper.getEverscaleTokens().tokens.find((token: any) => token.rootV5 === tokensInCurrentNetwork[0].tokenId).rootV5 : "",
      // token: "",
      wallet: "",
      address: accounts ? accounts[0] : ""
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: ({}, { setSubmitting }) => {}
  });

  const web3bnb = new Web3('https://bsc-dataseed1.binance.org:443');
  const web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/db64507e624f42ceb8f46a9d7d6280ff"))
  //const web3 = new Web3((window as any).web3.currentProvider);

  const abiJson = [
    {"constant":true,"inputs":[{"name":"who","type":"address"}],"name":"balanceOf","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},
  ];

  useEffect(() => {
    if (ethereum && ethereum.networkVersion && accounts) {
      formik.setFieldValue("chain", ethereum.networkVersion);
    }
  }, [ethereum, accounts]);

  useEffect(() => {
    if (everAddress) {
      formik.setFieldValue("wallet", everAddress);
    }
  }, [everAddress]);

  useEffect(() => {
    if (everAddress && accounts) {
      formik.setFieldValue("address", accounts[0]);
    }
  }, [accounts]);

  useEffect(() => {
    setTokensInCurrentNetwork([]);
    if (typeof window.ethereum !== 'undefined') {
      setEthereumProvider(window.ethereum);
    }
    if (formik.values.chain) {
      const nets = ExtensionHelper.getNetworks();
      const tokens = ExtensionHelper.getNetworkTokens(parseInt(nets.find((net: any) => String(net) === String(formik.values.chain))));

      setTimeout(() => {
        setTokensInCurrentNetwork(tokens);
      }, 200);

      setChainMeta(ExtensionHelper.chainEndpoints.find((endpoint: any) => String(endpoint.id) === String(formik.values.chain)));
      formik.setFieldValue("token", tokens[0].rootV5);
      // setExpectedExchangeRate(undefined);
    }
  }, [formik.values.chain]);

  useEffect(() => {
    if (formik.values.token) {

      setExpectedExchangeRate(undefined);
      if (tokensInCurrentNetwork.length) {
        setTokenMeta(tokensInCurrentNetwork.find((token: any) => token.tokenId === formik.values.token))
      }

      const getPairAddress = async () => await DebotClient.getExpectedPairAddress(formik.values.token);
      getPairAddress().then(result => setPairAddress(result));
    }
  }, [formik.values.token, tokensInCurrentNetwork]);

  useEffect(() => {
    if (pairAddress && tokenMeta && formik.values.amount && chainMeta) {
      const web3 = new Web3(chainMeta.url);
      const myVault = new web3.eth.Contract(
        ExtensionHelper.abi.Vault,
        tokenMeta.vault
      );
      const decimals = async () => +(await myVault.methods.tokenDecimals().call());

      const getExpectedExchangeRate = async () => await DebotClient.getExpectedExchangeRate(pairAddress, formik.values.token, formik.values.amount, await decimals());
      getExpectedExchangeRate().then(result => setExpectedExchangeRate(result?.decoded?.output));
    }
  }, [formik.values.amount, pairAddress]);

  useEffect(() => {
    // console.log(web3);
    web3.eth.getAccounts().then((value) => {
      // console.log(value)
      //web3bnb.eth.getBalance("0x9af60907001ff1a93ed5d908cedd8606945bd6d3").then((balance) => console.log(balance))
    })
    // web3bnb.eth.getBalance("0x9af60907001ff1a93ed5d908cedd8606945bd6d3").then((balance) => console.log(parseInt(balance) / 10**18));
    return () => {}
  }, [])

  useEffect(() => {
    if (ethereum) {
      (async () => await new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (ethereum?.isConnected()) {
            setEtheriumConnected(true);
            clearInterval(interval);
          }
        }, 100);
      }))()
    }
    return () => {}
  }, [ethereum]);

  async function ethereumConfiguration_getDetails(client: any, address: string) {
    // try{
    console.log(abiContract(ExtensionHelper.abi.EthereumEventConfiguration));
    const msg = await client.abi.encode_message({
      abi: abiContract(ExtensionHelper.abi.EthereumEventConfiguration),
      address,
      call_set: {
          function_name: 'getDetails',
          input: {answerId: 0}
      },
      signer: { type: 'None' }
    });
    const account = (await client.net.query_collection({
        collection: 'accounts',
        filter: { id: { eq: address } },
        timeout: 40000,
        result: 'boc'
    })).result[0].boc;
    return (await client.tvm.run_tvm({ message: msg.message, account, abi: abiContract(ExtensionHelper.abi.EthereumEventConfiguration) })).decoded.output;

  }
  
  async function creditFactory_getCreditProcessorAddress(client: any, address: any, configuration: any, eventVoteData: any) {
    try{
    const msg = await client.abi.encode_message({
      abi: abiContract(ExtensionHelper.abi.CreditFactory),
      address,
      call_set: {
          function_name: 'getCreditProcessorAddress',
          input: {answerId: 0,
                  eventVoteData,
                  configuration
          }
      },
      signer: signerNone()
    });
    const account = (await client.net.query_collection({
        collection: 'accounts',
        filter: { id: { eq: address } },
        timeout: 40000,
        result: 'boc'
    })).result[0].boc;
    return (await client.tvm.run_tvm({ message: msg.message, account, abi: abiContract(ExtensionHelper.abi.CreditFactory) })).decoded.output;
    } catch(e) {console.log(e)}
  }
  
  function sleep(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  const onSubmit = async (client: any) => {


    setTransactionStatusCode(1);
    // #1
    // выбрать сеть и получить значения из json
    // инициализация переменных

    //creditFactory актуальный адрес нужно брать из https://github.com/broxus/bridge-assets/blob/master/credit.json
    //если он меняется то все пропало, броксуус что-то поменял.
    const creditFactory = ExtensionHelper.creditAddress;
    // const mapEthBytesIntoTonCell = await abiConverter;

    // тут иконки есть для токенов everscale token list https://raw.githubusercontent.com/broxus/everscale-assets-upgrade/master/main.json
    // и понять адреса рутов для tip3 в everscale
    // Адреса vault необходимо брать из https://raw.githubusercontent.com/broxus/bridge-assets/master/main.json (те для которых "depositType" == "credit")
    // если json меняется то все пропало, броксуус что-то поменял.
    /*USDT
    {
      "chainId": "56",
      "vault": "0x5d767d4e250b5c8640cb2bf7e7cd3acaeb7768e1",
      "ethereumConfiguration": "0:79c92057392371647e3578c93650a5692aa844f427af3a9c92d204c911c708ef",
      "depositType": "credit"
    },*/

    // const tokenMeta = tokensInCurrentNetwork.find((token: any) => token.tokenId === formik.values.token);
    const vaultAddr = tokenMeta.vault;
    const ethereumConfiguration = tokenMeta.ethereumConfiguration;

    // aдрес нашего контракта который берет 0.5% он константа
    const recipientSurf = ExtensionHelper.recipientSurf;

    //адрес юзера из метамаска
    const myAddress = formik.values.address;
    //адрес юзера из эвервалета
    const myEverAddress = formik.values.wallet;

    //инит web3 для BNB. где для других сетей нати эндпоинты хз.
    // const chainMeta = ExtensionHelper.chainEndpoints.find((endpoint: any) => endpoint.id === formik.values.chain);
    const web3 = new Web3(chainMeta.url);
    const networkId = await web3.eth.net.getId();
    console.log("networkId", networkId);

    //добавляем приватник кошелека для автоподписи
    // await web3.eth.accounts.wallet.add(privateKey);
    // console.log(web3.eth.accounts.wallet)
    // const web3__ = web3;
    // debugger;
    


    //*/
    //# 0
    // Слушаем баланс кошелька
    const filter = { id: { eq: formik.values.wallet } };
    const listener = (response: { result: any }, responseType: number) => {
      console.log("responseType of Ever balance subscription: ", responseType);
      if (responseType === 1) {

      }

      if (response?.result && response?.result.balance) {
          const balance = parseInt(response.result.balance, 16);
          setSurfBalance(Number(balance));
      }
    };

    const balance = parseInt((await client.net.query_collection(
      {
        collection: 'accounts',
        filter,
        result: 'balance',
      }
    )).result[0].balance, 16);

    setSurfBalance(balance);
    setSurfOldBalance(balance);

    const { handle } = await client.net.subscribe_collection(
        {
            collection: 'accounts',
            filter,
            result: 'balance',
        },
        listener,
    );

    //из vault достаем рут токена в BNB
    const myVault = new web3.eth.Contract(
      ExtensionHelper.abi.Vault,
      vaultAddr
    );

    //address BEP20 токена в BNB
    const bep20Root = await myVault.methods.token().call()
    console.log(`bep20 root value: ${bep20Root}`);
    const evmDecimals = +(await myVault.methods.tokenDecimals().call());
    console.log(`decimals value: ${evmDecimals}`);

    const myBEP20 = new web3.eth.Contract(
      ExtensionHelper.abi.ERC20,
      bep20Root
    );

    //*/
    //# 2
    //спрашиваем сколько юзер хочет перевести
    //для usdt decimals
    //в everscale 6
    //в bnb 18
    //todo надо округлить до минимальных децималов (тут это не сделано)
    const userTotalBalance = await myBEP20.methods.balanceOf(myAddress).call()
    console.log(`userTotalBalance: ${userTotalBalance}`);
    const amount = formik.values.amount;//1.5 USDT
    const bn_amount = new BigNumber(amount).shiftedBy(evmDecimals).dp(0, BigNumber.ROUND_UP).toFixed();
    console.log(bn_amount);

    //*/
    //# 3
    //проверяем что в ваулт можно делать депозит.
    const vaultDepositLimit = await myVault.methods.depositLimit().call();
    const vaultBalance = await myBEP20.methods.balanceOf(vaultAddr).call();
    console.log(`vaultDepositLimit: ${vaultDepositLimit}`);
    console.log(`vaultBalance: ${vaultBalance}`);
    if (BigInt(bn_amount) > BigInt(vaultDepositLimit - vaultBalance)) return;

    //*/
    //# 4
    //проверяем allowance и если надо делаем approve
    //(даем право распоряжаться нашими токенами в BEP20 контракте контракту Vault)
    const vaultAllowance = await myBEP20.methods.allowance(myAddress,vaultAddr).call();
    console.log(`allowance value for vault: ${vaultAllowance}`);
    if (vaultAllowance<bn_amount) {//делаем approve, для этого нужна подпись юзера
      //тут выдается allowance на очень большое число, число взял на getever
      //там есть 2 стратегии:
      //-делать апрув под каждый перевод, но это требует fee
      //-делать апрув 1 раз на большую сумму
      const tx = await myBEP20.methods.approve(vaultAddr, '340282366920938463426481119284349108225');

      const gas = await tx.estimateGas({from: myAddress}).catch((error: any) => {
        setTransactionError({
          code: "",
          message: error.message
        });
        setIsTransactionComplete(true);
      });
      if (!gas) {
        setTransactionError({
          code: "",
          message: "Error during gas estimation request"
        });
        setIsTransactionComplete(true);
        return;
      }
      const gasPrice = await web3.eth.getGasPrice();
      const data = tx.encodeABI();

      const nonce = await web3.eth.getTransactionCount(myAddress);
      console.log("nonce",nonce);
      const txData = {
        from: myAddress,
        to: bep20Root,
        data: data,
        gas: String(gas),
        gasPrice: parseInt(gasPrice).toString(16),
        nonce: String(nonce),
      };
      console.log(txData);
      if (typeof window.ethereum !== 'undefined') {
        const receipt = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [txData],
        }).catch(error => {
          setIsTransactionComplete(true);
          setTransactionError(error);
        });
        console.log(`Transaction: ${JSON.stringify(receipt)}`);
      }
    }

    //*/
    //# 5
    //вызываем Vault.depositToFactory. Vault сам спишет наши bep20 токены
    //пустая ячейка, она всегда такая
    const LEVEL_3_TVM_CELL = 'te6ccgEBAQEAAgAAAA==';

    //slippage
    const minSlippage = {
      numerator: '5',
      denominator: '1000'
    }

      //формируем транзакцию
      const tx = await myVault.methods.depositToFactory(
        // amount, нужно указывать
          bn_amount,
        // FreeTON workchainId
          '0',
        // user
          `0x${myEverAddress.split(':')[1]}`,
        // Это контракт CreditFactory, который дает 6 TON "в кредит" для перехода.
        // Из них примерно ~2.5 ton тратится на газ
        // Этот кредит гасится автоматически из суммы перевода, оставшийся газ будет передан в IReceiveTONsFromBridgeCallback(recipient).onReceiveTONsFromBridgeCallback(...)
        // актуальный адрес нужно брать из https://github.com/broxus/bridge-assets/blob/master/credit.json
          `0x${creditFactory.split(':')[1]}`,
        // recipient адрес нашего SURF сервиса взимания комиссий
          `0x${recipientSurf.split(':')[1]}`,
        // tokenAmount (ОСТАВИТЬ БЕЗ ИЗМЕНЕНИЙ, чтобы обмен прошел на всю сумму)
          '0',
        // tonAmount - сколько минимум ton должно прийти в IReceiveTONsFromBridgeCallback(recipient)
          '2000000000',
        // swapType (ОСТАВИТЬ БЕЗ ИЗМЕНЕНИЙ, чтобы обмен прошел на всю сумму)
          '1',
        // slippage 0.5% - это не slippage всей операции, а slippage для попыток обмена внутри контракта.
          minSlippage.numerator, minSlippage.denominator,
        // level3
          `0x${Buffer.from(LEVEL_3_TVM_CELL, 'base64').toString('hex')}`,
      );

      const gas = await tx.estimateGas({from: myAddress}).catch((error: any) => {
        setTransactionError({
          code: "",
          message: error.message
        });
        setIsTransactionComplete(true);
      });
      if (!gas) return;
      const gasPrice = await web3.eth.getGasPrice();
      const data = tx.encodeABI();
      const nonce = await web3.eth.getTransactionCount(myAddress);
      console.log("nonce",nonce);
      console.log("GasPrice", parseInt(gasPrice).toString(16));
      const txData = {
        from: myAddress,
        to: vaultAddr,
        data: data,
        gas: String(gas),
        gasPrice: parseInt(gasPrice).toString(16),
        nonce: String(nonce),
      };
      //send
      //Amen!
      // const receipt = await web3.eth.sendTransaction(txData);
      // try {
      const receipt = typeof window.ethereum !== 'undefined' ? 
      await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [txData],
      }).catch(error => {
        setIsTransactionComplete(true);
        setTransactionError(error);
      }) : {} as any;
      setIsTransactionComplete(true);
      setTransactionReceipt(receipt);

      console.log("Transaction receipt address: ", receipt);
      let txReceipt = await web3.eth.getTransactionReceipt(receipt);
      
      while (!txReceipt) {
        await sleep(500);
        txReceipt = await web3.eth.getTransactionReceipt(receipt)
      };
      console.log("Transaction receipt: ", txReceipt);
      // } catch (error) {
      //   debugger;
      //   setIsTransactionComplete(true);
      //   setTransactionError({
      //     code: "",
      //     message: String(error)
      //   });
      // }   
      setTransactionStatusCode(2);

    //*/
    //# 5
    // Слушаем баланс кошелька
    // const filter = { id: { eq: formik.values.wallet } };
    // const listener = (response: { result: any }, responseType: number) => {
    //     console.log("responseType of Ever balance subscription: ", responseType);
    //     if (responseType === 1) {

    //     }

    //     if (response?.result && response?.result.balance) {
    //         const balance = parseInt(Utils.hexToString(response.result.balance));
    //         debugger;
    //         setSurfBalance(Number(balance));
    //     }
    // };

    // const balance = parseInt(Utils.hexToString(await client.net.query_collection(
    //   {
    //       collection: 'accounts',
    //       filter,
    //       result: 'balance',
    //   }
    // )));

    // debugger;

    // const { handle } = await client.net.subscribe_collection(
    //     {
    //         collection: 'accounts',
    //         filter,
    //         result: 'balance',
    //     },
    //     listener,
    // );

    // console.log(handle);

    // const mapEthBytesIntoTonCell = (await abiConverter).mapEthBytesIntoTonCell;
    //*/
    //# 5.1
    //проверяем статус транзакции в сети BNB она должна уйти на определенную глубину

    const evmCfgDetails = await ethereumConfiguration_getDetails(client, ethereumConfiguration);
    const eventBlocksToConfirm = evmCfgDetails._networkConfiguration.eventBlocksToConfirm;
    console.log(`eventBlocksToConfirm`,eventBlocksToConfirm);
    let currentBlockNummber = await web3.eth.getBlockNumber();
    setTransactionBlockCounter({
      total: eventBlocksToConfirm,
      current: currentBlockNummber - txReceipt.blockNumber
    });
    while (eventBlocksToConfirm > currentBlockNummber - txReceipt.blockNumber) {
      await sleep(1000);
      currentBlockNummber = await web3.eth.getBlockNumber();
      setTransactionBlockCounter({
        total: eventBlocksToConfirm,
        current: currentBlockNummber - txReceipt.blockNumber
      })
    } 

    setTransactionStatusCode(3);
    //*/
    //# 6
    //теперь должн появиться CreditProcessor, если сервис работает
    //нам нужно узнать его адрес
    //[debot] можем завернуть половину этого в дебота

    keepNonDecodedLogs()
    addABI(ExtensionHelper.abi.Vault)

    const decodedLogs = decodeLogs(txReceipt?.logs || [])
    //console.log(decodedLogs);
    const log = txReceipt.logs[decodedLogs.findIndex(
        (l: any) => l !== undefined && l.name === 'FactoryDeposit',
    )]

    if (log?.data == null || ethereumConfiguration === undefined) {
        return
    }

    //todo fix it
    // //import { mapEthBytesIntoTonCell } from 'eth-ton-abi-converter'
    // const eventData = mapEthBytesIntoTonCell(
    //   Buffer.from(evmCfgDetails._basicConfiguration.eventABI, 'base64').toString(),
    //   log.data,
    // );
    // //const eventData = await encodeCellPayload(client)

    // const eventVoteData = {
    //   eventBlock: txReceipt.blockHash,
    //   eventBlockNumber: txReceipt.blockNumber.toString(),
    //   eventData,
    //   eventIndex: log.logIndex.toString(),
    //   eventTransaction: txReceipt.transactionHash,
    // }

    // const creditProcessorAddress = (await creditFactory_getCreditProcessorAddress(client, ExtensionHelper.abi.CreditFactory, ethereumConfiguration, eventVoteData)).value0
    // console.log("CreditProcessor address",creditProcessorAddress);

    setTransactionStatusCode(4);
  }

  useEffect(() => {
    console.log("useEffect of SurfBalance!!!");
    if (surfBalance && surfOldBalance) {
      const delta = surfBalance - surfOldBalance;
      const expectedAmount = parseInt(expectedExchangeRate.expected_amount);
      if (surfBalance && surfOldBalance && delta > expectedAmount*0.8 && delta < expectedAmount*1.2) {
        setIsTransactionComplete(true);
        setTransactionStatusCode(5);
      }
    }
    setSurfOldBalance(surfBalance);
  }, [surfBalance])
  
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={cnb({ "active": false }, "form")}>
        <Swiper
          slidesPerView={1}
          allowTouchMove={false}
          navigation={false}
          effect={"fade"}
          modules={[EffectFade]}
          fadeEffect={{ crossFade: true }}
          speed={300}
          
          pagination={false}
          scrollbar={false}
          spaceBetween={3000}
          onSwiper={(swiper) => {setSwiper(swiper)}}
          onSlideChange={(swiper) => {
            setActiveSlide(swiper.activeIndex)
          }}
          className={cntr("transfer-page")}
        >
          <SwiperSlide>
          <Panel
            header={<h5 className={"title title-normal"}>Transfer tokens</h5>}
          >
            <FormControl>
              <Panel
                className={cntr("disclaimer")}
                type={"content"}
              >
                <FlexContainer
                  direction="row"
                  justify="space-between"
                  align="flex-start"
                >
                  <Flex grow={0}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M11.0403 4.48731L11.0403 4.48736L3.07767 18.9518L3.07766 18.9518C2.95846 19.1683 2.97879 19.3856 3.1253 19.5876C3.28088 19.802 3.5938 20 4.0373 20H19.9627C20.4062 20 20.7191 19.802 20.8747 19.5875C21.0212 19.3856 21.0416 19.1683 20.9224 18.9518L12.9597 4.48736L12.9597 4.48732C12.8044 4.20525 12.4545 4 12 4C11.5455 4 11.1957 4.20525 11.0403 4.48731ZM12 2C13.0123 2 14.1292 2.46477 14.7117 3.52276L14.7117 3.5228L22.6744 17.9872L21.7984 18.4695L22.6744 17.9872C23.2011 18.944 23.0522 19.992 22.4936 20.7619C21.9442 21.5194 21.0163 22 19.9627 22H4.0373C2.98372 22 2.05585 21.5194 1.50639 20.7619C0.947857 19.992 0.798902 18.944 1.32561 17.9872L1.32562 17.9872L9.28827 3.52281L9.2883 3.52276C9.87079 2.46477 10.9877 2 12 2ZM12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V9C11 8.44772 11.4477 8 12 8ZM13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17V17.0195C11 17.5718 11.4477 18.0195 12 18.0195C12.5523 18.0195 13 17.5718 13 17.0195V17Z" fill="#FF4747"/>
                    </svg>
                  </Flex>
                  <Flex grow={1}>This is an alpha version: errors and malfunctions may occur.</Flex>
                </FlexContainer>
              </Panel>
            </FormControl>
            <FormControl>
              <MetaMask
                onConnect={(accounts) => {
                  if (accounts && accounts.length) {
                    setAccounts(accounts)
                  } else {
                    
                  }
                }}
                onDisconnect={() => {
                  setAccounts(undefined);
                }}
              />
            </FormControl>
            <Select<FormValues>
              name={"chain"}
              value={formik.values.chain}
              title={"Send from"}
              options={chainList.map((chain) => ({
                icon: undefined,
                disabled: chain.disabled,
                selected: false,
                value: chain.chainID,
                title: <FlexContainer justify="space-between"><Flex grow={9999}>{chain.title}</Flex></FlexContainer>,
                caption: <FlexContainer justify="space-between"><Flex grow={9999}>{chain.title}</Flex></FlexContainer>
              }))}
              onSelect={(value: ISelectOption) => {
                (async () => {
                  if (ethereum)
                  await ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: web3.utils.toHex(value.value) }]
                  }).then(() => {
                    formik.setFieldValue("chain", value.value);
                  });
                })()
              }}

              className={cnb("cards-select")}
              color="default"
              iconRight={{
                icon: <Icon icon={"chevron-down"} />,
                animation: "left"
              }}
              variant={"action"}
              placeholder={"Select network"}
              size={"large"}
              disabled={!accounts}
            />

            <Field
              name={"wallet"}
              as={Input}
              clear={true}
              label={"Address"}
              // caption={"Type carefully"}
              placeholder={"Address"}
              composition={"substitute"}
              title={"To Everscale"}
              validation={formik.errors["wallet"]}
              disabled={!accounts}
            />
            
            <InputSelect<FormValues>
              title={"Amount"}
              label={"Amount"}
              placeholder={"Transfer amount"}
              composition={"substitute"}

              name={"amount"}
              nameSelect={"token"}
              type={"number"}
              
              validation={formik.errors["amount"] || formik.errors["token"]}
              
              value={formik.values.amount}
              valueSelect={formik.values.token}
              defaultValueSelect={tokensInCurrentNetwork.length ? ExtensionHelper.getEverscaleTokens().tokens.find((token: any) => token.rootV5 === tokensInCurrentNetwork[0].tokenId) : ""}

              onChange={formik.handleChange}
              onChangeSelect={formik.handleChange}
              onSelect={(value: ISelectOption) => {
                formik.setFieldValue("token", value.value);
              }}
              options={tokensInCurrentNetwork.length ? tokensInCurrentNetwork.map((token: any) => {
                const tokenMeta = ExtensionHelper.getEverscaleTokens().tokens.find((tkn: any) => tkn.rootV5 === token.tokenId);
                return ({
                icon: <img src={tokenMeta.logoURI}/>,
                disabled: false,
                value: token.tokenId,
                caption: tokenMeta.symbol,
                title: tokenMeta.symbol
              })}) : []}
              // selected={{
              //   icon: undefined,
              //   disabled: chainList[0].disabled,
              //   value: chainList[0].chainID,
              //   title: <FlexContainer justify="space-between"><Flex grow={9999}>{chainList[0].title}</Flex></FlexContainer>
              // }}

              className={cnb("cards-select")}
              color="default"
              action={<FlexContainer justify="center" align="center">ETH &nbsp;<Icon icon="chevron-down" /></FlexContainer>}
              variant={"action"}
              disabled={!accounts}
            />
            <SlideWrapper direction={!accounts || !formik.values.wallet || !formik.values.amount || Boolean(formik.errors.wallet) || Boolean(formik.errors.amount) ? undefined : "next"}>  
              <Button
                className={""}
                size="large"
                variant="button"
                color="primary"
                disabled={!accounts || !formik.values.wallet || !formik.values.amount || Boolean(formik.errors.wallet) || Boolean(formik.errors.amount)}
                onClick={() => {}}  
              >
                Review Order
              </Button>
            </SlideWrapper>
          </Panel>

          </SwiperSlide>
          <SwiperSlide>
            <Panel
              className={cntr("panel-with-button")}
              header={
                <SlideWrapper direction={"prev"}>  
                  <Icon className={cntr("button-icon")} icon="arrow-left"/> <div className={cnb("title", "title-normal")}>Back</div>
                </SlideWrapper>
              }
            >
              <FlexContainer
                justify="flex-start"
                align="stretch"
                direction="column"
                style={{
                  flexGrow: 1
                }}
              >
                <Flex>
                  <Panel
                    className={cntr("panel-content")}
                    type="content"
                    header={<h6 className={cnb("title", "title-normal")}>Estimated balance changes</h6>}
                  >
                    <FlexContainer
                      justify="flex-start"
                      align="stretch"
                      direction="column"
                    >
                      <Flex>
                        <dl className={cntr("card-summary", "paragraph", "paragraph-normal")}>
                          <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>You pay</dt><dd>{formik.values.amount} {formik.values.token ? ExtensionHelper.getEverscaleTokens().tokens.find((token: any) => token.rootV5 === formik.values.token)?.symbol : ""}</dd>
                          </FlexContainer>
                          <FlexContainer
                            className={cntr("card-summary-row", {"color-warning": expectedExchangeRate && (parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9 < 0)})}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph", {"color-warning": expectedExchangeRate && (parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9 < 0)})}>You get</dt><dd>{expectedExchangeRate ? floatDecimals((parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9)*0.995 / 10**9, 2) : <LoaderDotsText/>} EVER</dd>
                          </FlexContainer>
                          <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>Fee</dt><dd>up to {expectedExchangeRate ? floatDecimals((parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9)*0.005 / 10**9 + 2.5 + expectedExchangeRate.expected_fee / 10**9, 2) : <LoaderDotsText/>} EVER</dd>
                          </FlexContainer>
                          {/* <FlexContainer
                            className={cnb("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cnb("color-faded", "paragraph")}>Processing fee</dt><dd><LoaderDotsText/>&nbsp;EVER</dd>
                          </FlexContainer> */}
                        </dl>

                      </Flex>
                    </FlexContainer>
                  </Panel>
                </Flex>
                <Flex
                  grow={1}
                >
                  <Panel
                    type="content"
                    className={cntr("panel-content")}
                    collapse={true}
                    header={<h6 className={cnb("title", "title-normal")}>Fee details</h6>}
                  >
                    <FlexContainer
                      justify="flex-start"
                      align="stretch"
                      direction="column"
                    >
                      <Flex
                        grow={10}
                      >
                      </Flex>
                      <Flex>
                        <dl className={cntr("card-summary", "paragraph", "paragraph-normal")}>
                          <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>Provider</dt><dd>{expectedExchangeRate ? floatDecimals((parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9)*0.005 / 10**9, 4) : <LoaderDotsText/>} EVER</dd>
                          </FlexContainer>
                          <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>Execution</dt><dd>~ {expectedExchangeRate ? 2.5 : <LoaderDotsText/>} EVER</dd>
                          </FlexContainer>
                          {/* <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>Execution</dt><dd>{expectedExchangeRate ? expectedExchangeRate.expected_fee / 10**9 : <LoaderDotsText/>} EVER</dd>
                          </FlexContainer> */}
                        </dl>

                      </Flex>
                    </FlexContainer>
                  </Panel>
                </Flex>
                <Flex>
                  <SlideWrapper direction={"next"}>  
                    <Button
                      className={cntr("button-cta")}
                      size="large"
                      variant="button"
                      color="primary"
                      // iconLeft={{
                      //   animation: "none",
                      //   icon: <Icon icon="arrow-left"/>
                      // }}
                      disabled={expectedExchangeRate && (parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9 < 0)}
                      onClick={(e) => {
                        onSubmit(client)
                      }}
                    >
                      Proceed to transfer
                    </Button>
                  </SlideWrapper>
                </Flex>
              </FlexContainer>
            </Panel>
          </SwiperSlide>
          <SwiperSlide>
            <Panel
              className={cntr("panel-with-button")}
              header={
                <FlexContainer
                  justify="space-between"
                  align="center"
                  style={{width: "100%"}}
                >
                  <Flex>
                    <SlideWrapper direction={"prev"}>  
                      <div className={cnb("title", "title-normal")}>Transfer status</div>
                    </SlideWrapper>
                  </Flex>
                  <Flex>
                  
                  {/* <SlideWrapper direction={"prev"}> 
                    <Button
                      size="medium"
                      variant="button"
                      iconLeft={{
                        animation: "none",
                        icon: <Icon className={styles['']} icon={"close"} />
                      }}
                    >
                    </Button>
                  </SlideWrapper> */}
                  </Flex>
                </FlexContainer>
              }
              style={{
                position: "relative"
              }}
            >
              <div className={cntr("status-overlay", {"active": transactionError || transactionStatusCode === 5, "completed": transactionStatusCode === 5, "failed": transactionError})}>
                Transfer {!transactionError ? "completed" : "failed"}
              </div>
              <FlexContainer
                justify="space-between"
                align="stretch"
                direction="column"
                style={{flexGrow: 1}}
              >
                <Flex>
                    <FlexContainer
                      justify="flex-start"
                      align="stretch"
                      direction="column"
                    >
                      <Flex>
                        <dl className={cntr("card-summary", "paragraph", "paragraph-normal")}>
                          <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>Transfer tokens to vault</dt>
                            <dd className={cnb("title", "title-small")}>
                              {transactionStatusCode < 2
                                ? transactionStatusCode === 0
                                  ? <Status type="awaiting">Awaiting</Status>
                                  : <Status type="pending"><LoaderThreeDots/>&nbsp;&nbsp;&nbsp;Pending</Status>
                                : <Status type="success"><svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6643 5.56944C13.9788 5.20253 13.9364 4.65014 13.5694 4.33565C13.2025 4.02116 12.6501 4.06365 12.3357 4.43056L13.6643 5.56944ZM7 12L6.38128 12.6187C6.55362 12.7911 6.79003 12.8837 7.03358 12.8744C7.27713 12.865 7.50573 12.7545 7.66435 12.5694L7 12ZM4.61872 8.38128C4.27701 8.03957 3.72299 8.03957 3.38128 8.38128C3.03957 8.72299 3.03957 9.27701 3.38128 9.61872L4.61872 8.38128ZM12.3357 4.43056L6.33565 11.4306L7.66435 12.5694L13.6643 5.56944L12.3357 4.43056ZM3.38128 9.61872L6.38128 12.6187L7.61872 11.3813L4.61872 8.38128L3.38128 9.61872Z"/>
                                    </svg> Done
                                  </Status>
                              }
                            </dd>
                          </FlexContainer>
                          <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>Crossing the bridge</dt>
                            <dd className={cnb("title", "title-small")}>
                              {transactionStatusCode < 3
                                ? transactionStatusCode === 2
                                  ? <Status type="pending"><LoaderThreeDots/>&nbsp;&nbsp;&nbsp;Checking {transactionBlockCounter?.total && <>{transactionBlockCounter?.current} / {transactionBlockCounter?.total}</>}</Status>
                                  : <Status type="awaiting">&middot;&nbsp;&nbsp;&nbsp;Awaiting</Status>
                                : <Status type="success"><svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6643 5.56944C13.9788 5.20253 13.9364 4.65014 13.5694 4.33565C13.2025 4.02116 12.6501 4.06365 12.3357 4.43056L13.6643 5.56944ZM7 12L6.38128 12.6187C6.55362 12.7911 6.79003 12.8837 7.03358 12.8744C7.27713 12.865 7.50573 12.7545 7.66435 12.5694L7 12ZM4.61872 8.38128C4.27701 8.03957 3.72299 8.03957 3.38128 8.38128C3.03957 8.72299 3.03957 9.27701 3.38128 9.61872L4.61872 8.38128ZM12.3357 4.43056L6.33565 11.4306L7.66435 12.5694L13.6643 5.56944L12.3357 4.43056ZM3.38128 9.61872L6.38128 12.6187L7.61872 11.3813L4.61872 8.38128L3.38128 9.61872Z"/>
                                    </svg> Done 
                                  </Status>
                              }
                            </dd>
                          </FlexContainer>
                          <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>Transfer tokens to wallet</dt>
                            <dd className={cnb("title", "title-small")}>
                            {transactionStatusCode < 4
                              ? <Status>&middot;&nbsp;&nbsp;&nbsp;Awaiting</Status>
                              : transactionStatusCode === 4 
                                ? <Status type="pending"><LoaderThreeDots/>&nbsp;&nbsp;&nbsp;Pending</Status>
                                : <Status type="success"><svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6643 5.56944C13.9788 5.20253 13.9364 4.65014 13.5694 4.33565C13.2025 4.02116 12.6501 4.06365 12.3357 4.43056L13.6643 5.56944ZM7 12L6.38128 12.6187C6.55362 12.7911 6.79003 12.8837 7.03358 12.8744C7.27713 12.865 7.50573 12.7545 7.66435 12.5694L7 12ZM4.61872 8.38128C4.27701 8.03957 3.72299 8.03957 3.38128 8.38128C3.03957 8.72299 3.03957 9.27701 3.38128 9.61872L4.61872 8.38128ZM12.3357 4.43056L6.33565 11.4306L7.66435 12.5694L13.6643 5.56944L12.3357 4.43056ZM3.38128 9.61872L6.38128 12.6187L7.61872 11.3813L4.61872 8.38128L3.38128 9.61872Z"/>
                                    </svg> Done
                                  </Status>
                            }</dd>
                          </FlexContainer>
                        </dl>

                      </Flex>
                    </FlexContainer>
                </Flex>
                <Flex>
                  <FlexContainer
                    justify="flex-start"
                    align="stretch"
                    direction="column"
                  >
                  {transactionError 
                  ? <div className={cntr("console-ouput")}>
                      <div className={cntr("title")}>{transactionError.code}</div>
                      {transactionError.message}
                  </div>
                  : transactionReceipt && <Input
                    value={transactionReceipt}
                    name={"transactionReceipt"}
                    label={"Transaction address"}
                    placeholder={"Address"}
                    readOnly={true}
                    composition={"substitute"}
                    title={"Transaction address"}
                    disabled={true}
                  />}
                  {/* <SlideWrapper
                    className={cntr("transaction-receipt")}
                    direction={!accounts || !formik.values.wallet || !formik.values.amount || Boolean(formik.errors.wallet) || Boolean(formik.errors.amount) ? undefined : "next"}
                  > */}
                    <Button
                      className={cntr("button-cta")}
                      size="large"
                      disabled={!isTransactionComplete}
                      variant="button"
                      type="button"
                      color="primary"
                      onClick={() => {
                        // I have no idea why but it only works if you call it twice
                        // swiper.slidePrev();
                        if (transactionError || transactionStatusCode === 5) {
                          swiper.slideTo(0);  
                        } else {
                          swiper.slidePrev();
                        }
                        setIsTransactionComplete(false);
                        setTransactionError(undefined);
                        setTransactionStatusCode(0);
                        setTransactionBlockCounter(undefined);
                      }}
                    >
                      Done
                    </Button>
                  {/* </SlideWrapper> */}
                  </FlexContainer>
                </Flex>
              </FlexContainer>
            </Panel>
          </SwiperSlide>
        </Swiper>
      </form>
    </FormikProvider>
  );
};

export default TransferForm;