import { useState } from "react";
import styles from "./GraphLine.module.scss";
import classnames from "classnames/bind";
import { Icon } from "../Icon";
import { FlexContainer, Flex } from "..";

const cnb = classnames.bind(styles);

export const GraphLine = ({ className = "", color = "none", amount}: {
  className?: string,
  color?: string,
  amount?: number,
}) => {
  return <FlexContainer
    direction="row"
    justify="flex-end"
    align="center"
    className={cnb("graph", "graph-line", className, (color ? "graph-" + color : undefined), (!amount ? "graph-zero" : undefined))}
  >
    <Flex>
      {(amount || 0) * 100}%
    </Flex>
    <Flex>
      <div className={cnb("bar")}>
        <div className={cnb("bar-line")} style={{width: ((amount || 0) * 100) + "%"}}></div>
      </div>
    </Flex>
  </FlexContainer>;
};

export default GraphLine;
