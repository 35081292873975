import { useWidgetParams } from '../../../controllers/useWidgetParams';
import { FlexSendForm } from "../../../layouts/forms/flex/FlexSendForm";
import { WidgetColors } from '../../../types';

const Send = () => {
  let widgetColors: WidgetColors = useWidgetParams();

  return (
    <FlexSendForm  widgetColors={widgetColors} />
  );
};

export default Send;
