import React, { useEffect, useState, ReactNode } from "react";
import { useFormik, Field, FormikProvider } from 'formik';
import BigNumber from 'bignumber.js';
import { TonClient, abiContract, signerNone, FieldAggregation } from '@eversdk/core';
import {
  addABI,
  decodeLogs,
  keepNonDecodedLogs,
} from 'abi-decoder';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/scss'; // core Swiper
import 'swiper/scss/pagination'; // Pagination module

import "swiper/scss/effect-fade";
import { EffectFade } from 'swiper';
import { Swiper as SwiperClass } from 'swiper/types';


import * as Yup from 'yup';
import { address } from "../../../interfaces";
import ExtensionHelper from "../../../client/extension";

import classnames from "classnames/bind";
import { WidgetColors } from '../../../types';
import { Button, Panel, Select, FlexContainer, Flex, Icon, LoaderThreeDots, LoaderDotsText, FormControl, ISelectOption, InputSelect, Loader } from "../../../components";

import Web3 from "web3";
import { Input } from "../../../components";
import { MetaMask } from "../../../patterns/MetaMask";
import { Status } from "../../../components";
import { useConnect } from "../../../controllers/useConnect";
import DebotClient from "../../../client/client";
import styles from '../Form.module.scss';
import stylesTransfer from './FlexSendForm.module.scss';

import type { MetaMaskInpageProvider } from "@metamask/providers";
import Utils from "../../../client/utils";
import { floatDecimals } from "../../../utils";

const cnb = classnames.bind(styles);
const cntr = classnames.bind(stylesTransfer);

const chainList = [{
  chainID: 1,
  disabled: false,
  name: "Ethereum Mainnet",
  title: "Ethereum",
  currency: "ETH"
}, {
  chainID: 56,
  disabled: false,
  name: "Binance Smart Chain Mainnet",
  title: "Binance Smart Chain",
  currency: "BNB"
}, {
  chainID: 137,
  disabled: true,
  name: "Polygon Mainnet",
  title: "Polygon",
  currency: "MATIC"
}, {
  chainID: 250,
  disabled: true,
  name: "Fantom Opera",
  title: "Fantom",
  currency: "FTM"
},];

interface FormValues {
  amount: string;
  token: address;
  wallet: string;
  chain: string;
  address: string;
}

// const Loader = () => (
//   <svg className={styles.spinner}>
//     <circle cx="20" cy="20" r="12"></circle>
//   </svg>
// );

const SlideWrapper = ({direction, children, className}: {direction: "next" | "prev" | undefined, children: ReactNode, className?: string}) => {
  const swiper = useSwiper();
  return (
    <div className={cnb("swiper-button-wrapper", className)} onClick={direction ? () => direction === "prev" ? swiper.slidePrev() : swiper.slideNext() : undefined}>{children}</div>
  );
}

type SwapFormProps = {
  widgetColors?: WidgetColors;
}

export const FlexSendForm = ({ widgetColors }: SwapFormProps) => {
  // swiper data
  const [swiper, setSwiper] = useState<SwiperClass>(useSwiper());
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const [accounts, setAccounts] = useState<any>();
  const [ethereum, setEthereumProvider] = useState<MetaMaskInpageProvider>();
  const [tokensInCurrentNetwork, setTokensInCurrentNetwork] = useState<any>(false);
  const [isEthereumConnected, setEtheriumConnected] = useState<boolean>(false);
  const [isTransactionComplete, setIsTransactionComplete] = useState<boolean>(false);
  const [transactionStatusCode, setTransactionStatusCode] = useState<number>(0);
  const [transactionError, setTransactionError] = useState<{
    code: string,
    message: string,
  } | undefined>();
  const [transactionBlockCounter, setTransactionBlockCounter] = useState<{
    total: number,
    current: number
  } | undefined>();


  const [surfBalance, setSurfBalance] = useState<number>();
  const [surfOldBalance, setSurfOldBalance] = useState<number>();
  const [pairAddress, setPairAddress] = useState<address>();
  const [transactionReceipt, setTransactionReceipt] = useState<address>("");
  const [expectedExchangeRate, setExpectedExchangeRate] = useState<any>();
  const [token, setToken] = useState<address>();
  const [tokenMeta, setTokenMeta] = useState<any>();
  const [chainMeta, setChainMeta] = useState<any>();

  const { address: everAddress, type } = useConnect();

  const client = new TonClient({
    network: {
        // Local Evernode SE instance URL here
        endpoints: ["https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d"]
    }
  });

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Nice try 😄')
      .required('')
      .positive('Nice try 😄')
      .min(0, 'Minimum 0')
      .max(200000, 'Max. 200,000 EVER'),
      //.matches(/^[0-9]+\.?[0-9]*$/, "Not a number"),
    token: Yup.string()
      .typeError('Nice try 😄')
      .matches(/^0:[a-f0-9]{64,64}$/, "Doesn't look like a valid token address")
      .required(''),
    address: Yup.string()
      .typeError('Nice try 😄')
      .required(''),
    chain: Yup.string()
      .typeError('Nice try 😄')
      .required(''),
    wallet: Yup.string()
      .matches(/^0:[a-f0-9]{64,64}$/, "Doesn't look like a valid EVER wallet address")
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      amount: "",
      chain: ethereum && ethereum.networkVersion && accounts ? ethereum.networkVersion : "",
      token: tokensInCurrentNetwork.length ? ExtensionHelper.getEverscaleTokens().tokens.find((token: any) => token.rootV5 === tokensInCurrentNetwork[0].tokenId).rootV5 : "",
      // token: "",
      wallet: "",
      address: accounts ? accounts[0] : ""
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: ({}, { setSubmitting }) => {}
  });

  const web3bnb = new Web3('https://bsc-dataseed1.binance.org:443');
  const web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/db64507e624f42ceb8f46a9d7d6280ff"))
  //const web3 = new Web3((window as any).web3.currentProvider);

  const abiJson = [
    {"constant":true,"inputs":[{"name":"who","type":"address"}],"name":"balanceOf","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},
  ];

  useEffect(() => {
    if (ethereum && ethereum.networkVersion && accounts) {
      formik.setFieldValue("chain", ethereum.networkVersion);
    }
  }, [ethereum, accounts]);

  useEffect(() => {
    if (everAddress) {
      formik.setFieldValue("wallet", everAddress);
    }
  }, [everAddress]);

  useEffect(() => {
    if (everAddress && accounts) {
      formik.setFieldValue("address", accounts[0]);
    }
  }, [accounts]);

  useEffect(() => {
    setTokensInCurrentNetwork([]);
    if (typeof window.ethereum !== 'undefined') {
      setEthereumProvider(window.ethereum);
    }
    if (formik.values.chain) {
      const nets = ExtensionHelper.getNetworks();
      const tokens = ExtensionHelper.getNetworkTokens(parseInt(nets.find((net: any) => String(net) === String(formik.values.chain))));

      setTimeout(() => {
        setTokensInCurrentNetwork(tokens);
      }, 200);

      setChainMeta(ExtensionHelper.chainEndpoints.find((endpoint: any) => String(endpoint.id) === String(formik.values.chain)));
      formik.setFieldValue("token", tokens[0].rootV5);
      // setExpectedExchangeRate(undefined);
    }
  }, [formik.values.chain]);

  useEffect(() => {
    if (formik.values.token) {

      setExpectedExchangeRate(undefined);
      if (tokensInCurrentNetwork.length) {
        setTokenMeta(tokensInCurrentNetwork.find((token: any) => token.tokenId === formik.values.token))
      }

      const getPairAddress = async () => await DebotClient.getExpectedPairAddress(formik.values.token);
      getPairAddress().then(result => setPairAddress(result));
    }
  }, [formik.values.token, tokensInCurrentNetwork]);

  useEffect(() => {
    if (pairAddress && tokenMeta && formik.values.amount && chainMeta) {
      const web3 = new Web3(chainMeta.url);
      const myVault = new web3.eth.Contract(
        ExtensionHelper.abi.Vault,
        tokenMeta.vault
      );
      const decimals = async () => +(await myVault.methods.tokenDecimals().call());

      const getExpectedExchangeRate = async () => await DebotClient.getExpectedExchangeRate(pairAddress, formik.values.token, formik.values.amount, await decimals());
      getExpectedExchangeRate().then(result => setExpectedExchangeRate(result?.decoded?.output));
    }
  }, [formik.values.amount, pairAddress]);

  useEffect(() => {
    // console.log(web3);
    web3.eth.getAccounts().then((value) => {
      // console.log(value)
      //web3bnb.eth.getBalance("0x9af60907001ff1a93ed5d908cedd8606945bd6d3").then((balance) => console.log(balance))
    })
    // web3bnb.eth.getBalance("0x9af60907001ff1a93ed5d908cedd8606945bd6d3").then((balance) => console.log(parseInt(balance) / 10**18));
    return () => {}
  }, [])

  useEffect(() => {
    if (ethereum) {
      (async () => await new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (ethereum?.isConnected()) {
            setEtheriumConnected(true);
            clearInterval(interval);
          }
        }, 100);
      }))()
    }
    return () => {}
  }, [ethereum]);

  async function ethereumConfiguration_getDetails(client: any, address: string) {
    // try{
    console.log(abiContract(ExtensionHelper.abi.EthereumEventConfiguration));
    const msg = await client.abi.encode_message({
      abi: abiContract(ExtensionHelper.abi.EthereumEventConfiguration),
      address,
      call_set: {
          function_name: 'getDetails',
          input: {answerId: 0}
      },
      signer: { type: 'None' }
    });
    const account = (await client.net.query_collection({
        collection: 'accounts',
        filter: { id: { eq: address } },
        timeout: 40000,
        result: 'boc'
    })).result[0].boc;
    return (await client.tvm.run_tvm({ message: msg.message, account, abi: abiContract(ExtensionHelper.abi.EthereumEventConfiguration) })).decoded.output;

  }
  
  async function creditFactory_getCreditProcessorAddress(client: any, address: any, configuration: any, eventVoteData: any) {
    try{
    const msg = await client.abi.encode_message({
      abi: abiContract(ExtensionHelper.abi.CreditFactory),
      address,
      call_set: {
          function_name: 'getCreditProcessorAddress',
          input: {answerId: 0,
                  eventVoteData,
                  configuration
          }
      },
      signer: signerNone()
    });
    const account = (await client.net.query_collection({
        collection: 'accounts',
        filter: { id: { eq: address } },
        timeout: 40000,
        result: 'boc'
    })).result[0].boc;
    return (await client.tvm.run_tvm({ message: msg.message, account, abi: abiContract(ExtensionHelper.abi.CreditFactory) })).decoded.output;
    } catch(e) {console.log(e)}
  }
  
  function sleep(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  const onSubmit = async (client: any) => {

  }

  useEffect(() => {
    console.log("useEffect of SurfBalance!!!");
    if (surfBalance && surfOldBalance) {
      const delta = surfBalance - surfOldBalance;
      const expectedAmount = parseInt(expectedExchangeRate.expected_amount);
      if (surfBalance && surfOldBalance && delta > expectedAmount*0.8 && delta < expectedAmount*1.2) {
        setIsTransactionComplete(true);
        setTransactionStatusCode(5);
      }
    }
    setSurfOldBalance(surfBalance);
  }, [surfBalance])
  
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={cnb({ "active": false }, "form")}>
        <Swiper
          slidesPerView={1}
          allowTouchMove={false}
          navigation={false}
          effect={"fade"}
          modules={[EffectFade]}
          fadeEffect={{ crossFade: true }}
          speed={300}
          
          pagination={false}
          scrollbar={false}
          spaceBetween={3000}
          onSwiper={(swiper) => {setSwiper(swiper)}}
          onSlideChange={(swiper) => {
            setActiveSlide(swiper.activeIndex)
          }}
          className={cntr("transfer-page")}
        >
          <SwiperSlide>
          <Panel
            header={<h5 className={"title title-normal"}>By Surf wallet</h5>}
            className={cntr("panel")}
          >
            
            <Input<FormValues>
              title={""}
              label={"Amount"}
              placeholder={"Amount"}
              composition={"substitute"}

              name={"amount"}
              type={"number"}
              
              validation={formik.errors["amount"] || formik.errors["token"]}
              
              value={formik.values.amount}
             
              onChange={formik.handleChange}

              units={"EVER"}

              className={cnb("cards-select")}
              color="default"
            />
            
            <SlideWrapper direction={!formik.values.amount || Boolean(formik.errors.amount) ? undefined : "next"}>  
              <Button
                className={""}
                size="large"
                variant="button"
                color="primary"
                disabled={!formik.values.amount || Boolean(formik.errors.amount)}
                onClick={() => {}}  
              >
                Get EVER
              </Button>
            </SlideWrapper>
          </Panel>

          </SwiperSlide>
          <SwiperSlide>
            <Panel
              className={cntr("panel-with-button", "panel")}
              header={
                <SlideWrapper direction={"prev"}>  
                  <Icon className={cntr("button-icon")} icon="arrow-left"/> <div className={cnb("title", "title-normal")}>Back</div>
                </SlideWrapper>
              }
            >
              <FlexContainer
                justify="flex-start"
                align="stretch"
                direction="column"
                style={{
                  flexGrow: 1
                }}
              >
                <Flex>
                  <Panel
                    className={cntr("panel-content")}
                    type="content"
                    header={<h6 className={cnb("title", "title-small")}>Estimated changes</h6>}
                  >
                    <FlexContainer
                      justify="flex-start"
                      align="stretch"
                      direction="column"
                    >
                      <Flex>
                        <dl className={cntr("card-summary", "paragraph", "paragraph-normal")}>

                          <FlexContainer
                            className={cntr("card-summary-row", {"color-warning": expectedExchangeRate && (parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9 < 0)})}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph", {"color-warning": expectedExchangeRate && (parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9 < 0)})}>You get</dt><dd>{expectedExchangeRate ? floatDecimals((parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9)*0.995 / 10**9, 2) : <LoaderDotsText/>} EVER</dd>
                          </FlexContainer>
                          <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>Fee</dt><dd> {expectedExchangeRate ? floatDecimals((parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9)*0.005 / 10**9 + 2.5 + expectedExchangeRate.expected_fee / 10**9, 2) : <LoaderDotsText/>} EVER</dd>
                          </FlexContainer>
                          {/* <FlexContainer
                            className={cnb("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cnb("color-faded", "paragraph")}>Processing fee</dt><dd><LoaderDotsText/>&nbsp;EVER</dd>
                          </FlexContainer> */}
                        </dl>

                      </Flex>
                    </FlexContainer>
                  </Panel>
                </Flex>
                <Flex>
                  <SlideWrapper direction={"next"}>  
                    <Button
                      className={cntr("button-cta")}
                      size="large"
                      variant="button"
                      color="primary"
                      // iconLeft={{
                      //   animation: "none",
                      //   icon: <Icon icon="arrow-left"/>
                      // }}
                      disabled={expectedExchangeRate && (parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9 < 0)}
                      onClick={(e) => {
                        onSubmit(client)
                      }}
                    >
                      Proceed to transfer
                    </Button>
                  </SlideWrapper>
                </Flex>
              </FlexContainer>
            </Panel>
          </SwiperSlide>
          <SwiperSlide>
            <Panel
              className={cntr("panel-with-button", "panel")}
              header={null}
              style={{
                position: "relative"
              }}
            >
              
              <FlexContainer
                  justify="center"
                  direction="column"
                  align="center"
                  style={{width: "100%", minHeight: "300px"}}
                >
                  <Flex
                    className={cnb("status-loader", "status-loader-inprogress")}
                  >
                    <Loader/>
                  </Flex>
                  <Flex>
                    In progress...
                  </Flex>
                </FlexContainer>
            </Panel>
          </SwiperSlide>
        </Swiper>
      </form>
    </FormikProvider>
  );
};

export default FlexSendForm;