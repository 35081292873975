import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import cn from "classnames";
import {
  isMobile
} from "react-device-detect";


import { TonClient } from "@eversdk/core";
import { libWeb, libWebSetup } from "@eversdk/lib-web";

import "./assets/styles/index.scss";

import Main from "./pages/main";
import Swap from "./pages/swap";
import FlexTransferWidget from "./pages/widgets/flex/transfer";
import FlexSendWidget from "./pages/widgets/flex/send";
import FlexBuyWidget from "./pages/widgets/flex/buy";
import SwapWidget from "./pages/widgets/swap";
import SendWidget from "./pages/widgets/send";
import TransferWidget from "./pages/widgets/transfer";
import Buy from "./pages/buy";
import Transfer from "./pages/transfer";
import Content from "./pages/content";
import GetEver from "./pages/GetEver/GetEver";
import { Header, Footer } from "./layouts";

const App = () => {
  const location: string = useLocation().pathname.split('/').filter(Boolean)[0];
  libWebSetup({
    binaryURL: "/assets/eversdk.wasm",
  });
  // @ts-ignore
  TonClient.useBinaryLibrary(libWeb);

  if (location !== "widget")
    return (
      <div className={cn("ws-app", location, {"isMobile": isMobile, "main": !location})}>
        <Header
          location={location || "main"}
        />
        <main>
          <Routes>
            <Route path="/legal-notes/:id" element={<Content/>} />
            <Route path="/swap" element={<Swap/>} />
            <Route path="/buy" element={<Buy/>} />
            <Route path="/transfer" element={<Transfer/>} />
            <Route path="/route" element={<></>} />
            <Route path="/getever" element={<GetEver/>} />
            <Route path="/" element={<Main/>} />
            <Route path="*" element={<Navigate to="/"/> }/>
          </Routes>
        </main>
        <Footer />
      </div>
    )
  else
    
  return (
    <Routes>
      <Route path="/widget/swap" element={<SwapWidget/>} />
      <Route path="/widget/send" element={<SendWidget/>} />
      <Route path="/widget/transfer" element={<TransferWidget/>} />
      <Route path="/widget/flex/transfer" element={<FlexTransferWidget/>} />
      <Route path="/widget/flex/send" element={<FlexSendWidget/>} />
      <Route path="/widget/flex/buy" element={<FlexBuyWidget/>} />
    </Routes>
  );
};

export default App;
