import React, { useEffect, useState, useRef, useCallback, ReactNode } from "react";
import { useFormik, Field, FormikProvider } from 'formik';
//import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { isMobile } from "react-device-detect";
import * as Yup from 'yup';


import classnames from "classnames/bind";
import QRCodeStyling from "qr-code-styling";

import {v4 as uuidv4} from 'uuid';

import { useCookies } from "react-cookie";
import { Panel, Input, FlexContainer, Flex, Icon, ModalSheet } from "../../components";
import { InputNumber, InputMasked, InputCVC } from "../../components/Form";
import { Button } from "../../components/button_";
import { floatDecimals } from "../../utils";
import styles from './Form.module.scss';
import stylesBuy from './BuyForm.module.scss';
import { InputComponent } from "../../components";

const cnb = classnames.bind(styles);
const cnbuy = classnames.bind(stylesBuy);

const __ENV = process.env.ENV ? process.env.ENV : 'prod';

const EVER_RATE:{[key: string]: number;} = {
  'USD': 0.4,
  'EUR': 0.5
}

const API_URL:{[key: string]: string} = {
  dev: 'http://localhost:3333',
  prod: 'https://buy.ever.surf/api'
}

const ENDPOINTS = {
  accessToken: '/accessToken'
};


interface FormValues {
  majorAmount: string;
  major: string;
  minorAmount: string;
  minor: string;
  wallet: string;
  everscale_addr: string;
  email: string;
  card: string;
  card_date: string;
  card_card: string;
  card_holder: string;
  
}


export const PaylerForm = () => {
  //const [cookies, setCookie, removeCookie] = useCookies(['sessionId', 'sumsubSessionToken']);
  // const [accessToken, setAccessToken] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>('');
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const refQr = useRef<HTMLDivElement>(null);
  const [invertRate, setInvertRate] = useState<boolean>(false);

  const qrCode = new QRCodeStyling({
    width: 350,
    height: 350,
    type: "svg",
    margin: 0,
    qrOptions: {
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "L"
    },
    imageOptions: {
        hideBackgroundDots: false,
        imageSize: 0,
        margin: 0
    },
    dotsOptions: {
        type: "rounded",
        color: "#000000"
    },
    backgroundOptions: {
        color: "transparent"
    },
    image: "",
    cornersSquareOptions: {
        type: undefined,
        color: "#000000"
    },
    cornersDotOptions: {
        type: undefined,
        color: "#000000"
    }
  });


  const [size, setSize] = useState<{
    height: number
    minHeight?: number
    minWidth?: number
    width: number
  }>({height: 830, width: 100});


  return (
    <div
      className={cnb("widget", "buy-widget")}
    >
      <iframe src={"https://pay.ever.surf/widget"} width={"100%"} height={size?.height + "px"}/> 
    </div>
  );
};

export default PaylerForm;