import { ActionTypes } from "../../types/action-types";
import {
  LOAD_TOKENS,
  SET_TOKENS,
  LOAD_PAIRS,
  SET_PAIRS,
  LOAD_RATE,
  SET_RATE
} from "../actions/constants";

import {
  Token,
  Rate,
  PairsQuantum
} from "../../interfaces";

import {
  State,
  getInitialState,
  setLoadingState,
  setSuccessState
} from '../../utils/store/PromiseUtils';

interface IInitialState {
  tokens: State<Token[]>,
  pairs: State<Token[]>,
  rate: State<Rate | undefined>,
}

const initialState: IInitialState = {
  tokens: getInitialState<Token[]>([]),
  pairs: getInitialState<Token[]>([]),
  rate: getInitialState<Rate | undefined>(undefined),
};

export const swapReducer = (
  state: IInitialState = initialState,
  { type, payload }: ActionTypes
):IInitialState => {
  switch (type) {
    case LOAD_PAIRS:
      return setLoadingState<Token[] | undefined, IInitialState>(state, 'pairs', []);
    case SET_PAIRS:
      return setSuccessState<Token[], IInitialState>(state, 'pairs', payload as Token[]);
    case LOAD_TOKENS:
      return setLoadingState<Token[] | undefined, IInitialState>(state, 'tokens', []);
    case SET_TOKENS:
      return setSuccessState<Token[], IInitialState>(state, 'tokens', payload as Token[]);
    case LOAD_RATE:
      return setLoadingState<Rate | undefined, IInitialState>(state, 'rate', undefined);
    case SET_RATE:
      return setSuccessState<Rate, IInitialState>(state, 'rate', payload as Rate);
    default:
      return state;
  }
};