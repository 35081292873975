import styles from "./Status.module.scss";
import classnames from "classnames/bind";

const cnb = classnames.bind(styles);

export const Status = ({ className = "", type, children}: {
  className?: string,
  type?: "success" | "failure" | "pending" | "awaiting",
  children?: React.ReactNode
}) => {
  return <div className={cnb("status", "title", "title-small", className, (type ? "status-" + type : "status-awaiting"))}>
    {children}
  </div>;
};

export default Status;
