export const abi = `{
	"ABI version": 2,
	"version": "2.2",
	"header": ["time"],
	"functions": [
		{
			"name": "onGetTokens",
			"inputs": [
				{"components":[{"name":"symbol","type":"string"},{"name":"name","type":"string"},{"name":"decimals","type":"uint8"},{"name":"addr","type":"address"},{"name":"logoURI","type":"string"},{"name":"version","type":"int8"}],"name":"tokens","type":"tuple[]"}
			],
			"outputs": [
			]
		},

		{
			"name": "onWrap",
			"inputs": [
				{"name":"status","type":"uint8"}
			],
			"outputs": [
			]
		},
		{
			"name": "onUnwrap",
			"inputs": [
				{"name":"status","type":"uint8"}
			],
			"outputs": [
			]
		},
		{
			"name": "onCalcRate",
			"inputs": [
				{"name":"success","type":"uint8"},
				{"components":[{"name":"expected","type":"uint128"},{"name":"expectedStr","type":"string"},{"name":"rate","type":"uint128"},{"name":"rateStr","type":"string"},{"name":"fee","type":"uint128"},{"name":"feeStr","type":"string"}],"name":"rate","type":"optional(tuple)"}
			],
			"outputs": [
			]
		},

		{
			"name": "onGetPairs",
			"inputs": [
				{"components":[{"name":"symbol","type":"string"},{"name":"name","type":"string"},{"name":"decimals","type":"uint8"},{"name":"addr","type":"address"},{"name":"logoURI","type":"string"},{"name":"version","type":"int8"}],"name":"tokens","type":"tuple[]"}
			],
			"outputs": [
			]
		},
		{
			"name": "onSwap",
			"inputs": [
				{"name":"status","type":"uint8"}
			],
			"outputs": [
			]
		}
	]
}`;

export default {
    abi
}