import React, { useEffect, useState, ReactNode } from "react";
import { useFormik, Field, FormikProvider } from 'formik';
import BigNumber from 'bignumber.js';
import { TonClient, abiContract, signerNone, FieldAggregation } from '@eversdk/core';
import {
  addABI,
  decodeLogs,
  keepNonDecodedLogs,
} from 'abi-decoder';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/scss'; // core Swiper
import 'swiper/scss/pagination'; // Pagination module

import "swiper/scss/effect-fade";
import { EffectFade } from 'swiper';
import { Swiper as SwiperClass } from 'swiper/types';


import * as Yup from 'yup';
import { address } from "../../../interfaces";
import ExtensionHelper from "../../../client/extension";

import classnames from "classnames/bind";
import { WidgetColors } from '../../../types';
import { Button, Panel, Select, FlexContainer, Flex, Icon, LoaderThreeDots, LoaderDotsText, FormControl, ISelectOption, InputSelect, Loader } from "../../../components";

import Web3 from "web3";
import { Input } from "../../../components";
import { MetaMask } from "../../../patterns/MetaMask";
import { Status } from "../../../components";
import { useConnect } from "../../../controllers/useConnect";
import DebotClient from "../../../client/client";
import styles from '../Form.module.scss';
import stylesTransfer from './FlexBuyForm.module.scss';

import type { MetaMaskInpageProvider } from "@metamask/providers";
import Utils from "../../../client/utils";
import { floatDecimals } from "../../../utils";

const cnb = classnames.bind(styles);
const cntr = classnames.bind(stylesTransfer);


interface FormValues {
  amountPay: string;
  amountGet: string;
  address: string;
  card_date: string;
  card: string;
  card_number: string;
}

// const Loader = () => (
//   <svg className={styles.spinner}>
//     <circle cx="20" cy="20" r="12"></circle>
//   </svg>
// );

const SlideWrapper = ({direction, children, className}: {direction: "next" | "prev" | undefined, children: ReactNode, className?: string}) => {
  const swiper = useSwiper();
  return (
    <div className={cnb("swiper-button-wrapper", className)} onClick={direction ? () => direction === "prev" ? swiper.slidePrev() : swiper.slideNext() : undefined}>{children}</div>
  );
}

type SwapFormProps = {
  widgetColors?: WidgetColors;
}

export const FlexBuyForm = ({ widgetColors }: SwapFormProps) => {
  // swiper data
  const [swiper, setSwiper] = useState<SwiperClass>(useSwiper());
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const [accounts, setAccounts] = useState<any>();
  const [ethereum, setEthereumProvider] = useState<MetaMaskInpageProvider>();
  const [tokensInCurrentNetwork, setTokensInCurrentNetwork] = useState<any>(false);
  const [isEthereumConnected, setEtheriumConnected] = useState<boolean>(false);
  const [isTransactionComplete, setIsTransactionComplete] = useState<boolean>(false);
  const [transactionStatusCode, setTransactionStatusCode] = useState<number>(0);
  const [transactionError, setTransactionError] = useState<{
    code: string,
    message: string,
  } | undefined>();
  const [transactionBlockCounter, setTransactionBlockCounter] = useState<{
    total: number,
    current: number
  } | undefined>();


  const [surfBalance, setSurfBalance] = useState<number>();
  const [surfOldBalance, setSurfOldBalance] = useState<number>();
  const [pairAddress, setPairAddress] = useState<address>();
  const [transactionReceipt, setTransactionReceipt] = useState<address>("");
  const [expectedExchangeRate, setExpectedExchangeRate] = useState<any>();
  const [token, setToken] = useState<address>();
  const [tokenMeta, setTokenMeta] = useState<any>();
  const [chainMeta, setChainMeta] = useState<any>();

  const { address: everAddress, type } = useConnect();

  const client = new TonClient({
    network: {
        // Local Evernode SE instance URL here
        endpoints: ["https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d"]
    }
  });

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Nice try 😄')
      .required('')
      .positive('Nice try 😄')
      .min(0, 'Minimum 0')
      .max(200000, 'Max. 200,000 EVER'),
      //.matches(/^[0-9]+\.?[0-9]*$/, "Not a number"),
    token: Yup.string()
      .typeError('Nice try 😄')
      .matches(/^0:[a-f0-9]{64,64}$/, "Doesn't look like a valid token address")
      .required(''),
    address: Yup.string()
      .typeError('Nice try 😄')
      .required(''),
    chain: Yup.string()
      .typeError('Nice try 😄')
      .required(''),
    wallet: Yup.string()
      .matches(/^0:[a-f0-9]{64,64}$/, "Doesn't look like a valid EVER wallet address")
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      amountPay: "",
      amountGet: "",
      card: "",
      card_date: "",
      card_number: "",
      address: accounts ? accounts[0] : ""
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: ({}, { setSubmitting }) => {}
  });


  useEffect(() => {

  }, []);

  const onSubmit = async (client: any) => {

  }

  useEffect(() => {
    console.log("useEffect of SurfBalance!!!");
    if (surfBalance && surfOldBalance) {
      const delta = surfBalance - surfOldBalance;
      const expectedAmount = parseInt(expectedExchangeRate.expected_amount);
      if (surfBalance && surfOldBalance && delta > expectedAmount*0.8 && delta < expectedAmount*1.2) {
        setIsTransactionComplete(true);
        setTransactionStatusCode(5);
      }
    }
    setSurfOldBalance(surfBalance);
  }, [surfBalance])
  
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={cnb({ "active": false }, "form")}>
        <Swiper
          slidesPerView={1}
          allowTouchMove={false}
          navigation={false}
          effect={"fade"}
          modules={[EffectFade]}
          fadeEffect={{ crossFade: true }}
          speed={300}
          
          pagination={false}
          scrollbar={false}
          spaceBetween={3000}
          onSwiper={(swiper) => {setSwiper(swiper)}}
          onSlideChange={(swiper) => {
            setActiveSlide(swiper.activeIndex)
          }}
          className={cntr("transfer-page")}
        >
          <SwiperSlide>
          <Panel
            header={<h5 className={"title title-normal"}>By bank card</h5>}
            className={cntr("panel")}
          >
            
            <Input<FormValues>
              title={""}
              label={"You pay"}
              placeholder={"You pay"}
              composition={"substitute"}

              name={"amountPay"}
              type={"number"}
              
              validation={formik.errors["amountPay"]}
              
              value={formik.values.amountPay}
             
              onChange={formik.handleChange}

              units={"USD"}

              className={cnb("cards-select")}
              color="default"
            />

            <Input<FormValues>
              title={""}
              label={"You get"}
              placeholder={"You get"}
              composition={"substitute"}

              name={"amountGet"}
              type={"number"}
              
              validation={formik.errors["amountGet"]}
              
              value={formik.values.amountGet}
             
              onChange={formik.handleChange}

              units={"EVER"}

              className={cnb("cards-select")}
              color="default"
              caption="Min. 250—250,000 max."
              captionSecondary={"1 Ē ≈ 0.04 $"}
            />
            
            <SlideWrapper direction={!formik.values.amountPay || !formik.values.amountGet || Boolean(formik.errors.amountPay) || Boolean(formik.errors.amountGet) ? undefined : "next"}>  
              <Button
                className={""}
                size="large"
                variant="button"
                color="primary"
                disabled={!formik.values.amountPay || Boolean(formik.errors.amountGet)}
                onClick={() => {}}  
              >
                Get EVER
              </Button>
            </SlideWrapper>
          </Panel>

          </SwiperSlide>
          <SwiperSlide><Panel
              className={cntr("panel-with-button", "panel")}
              header={
                <SlideWrapper direction={"prev"}>  
                  <Icon className={cntr("button-icon")} icon="arrow-left"/> <div className={cnb("title", "title-normal")}>Back</div>
                </SlideWrapper>
              }
            >
              <FlexContainer
                justify="flex-start"
                align="stretch"
                direction="column"
                style={{
                  flexGrow: 1
                }}
              >
                <Flex>
                  <Panel
                    className={cntr("panel-content")}
                    type="content"
                    header={<h6 className={cnb("title", "title-small")}>Estimated balance changes</h6>}
                  >
                    <FlexContainer
                      justify="flex-start"
                      align="stretch"
                      direction="column"
                    >
                      <Flex>
                        <dl className={cntr("card-summary", "paragraph", "paragraph-normal")}>
                          <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>You pay</dt><dd>{formik.values.amountPay}</dd>
                          </FlexContainer>
                          <FlexContainer
                            className={cntr("card-summary-row", {"color-warning": expectedExchangeRate && (parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9 < 0)})}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph", {"color-warning": expectedExchangeRate && (parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9 < 0)})}>You get</dt><dd>{expectedExchangeRate ? floatDecimals((parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9)*0.995 / 10**9, 2) : <LoaderDotsText/>} EVER</dd>
                          </FlexContainer>
                          <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>Fee</dt><dd>up to {expectedExchangeRate ? floatDecimals((parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9)*0.005 / 10**9 + 2.5 + expectedExchangeRate.expected_fee / 10**9, 2) : <LoaderDotsText/>} EVER</dd>
                          </FlexContainer>
                          {/* <FlexContainer
                            className={cnb("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cnb("color-faded", "paragraph")}>Processing fee</dt><dd><LoaderDotsText/>&nbsp;EVER</dd>
                          </FlexContainer> */}
                        </dl>

                      </Flex>
                    </FlexContainer>
                  </Panel>
                </Flex>
                <Flex
                  grow={1}
                >
                  <Panel
                    type="content"
                    className={cntr("panel-content")}
                    collapse={true}
                    header={<h6 className={cnb("title", "title-small")}>Fee details</h6>}
                  >
                    <FlexContainer
                      justify="flex-start"
                      align="stretch"
                      direction="column"
                    >
                      <Flex
                        grow={10}
                      >
                      </Flex>
                      <Flex>
                        <dl className={cntr("card-summary", "paragraph", "paragraph-normal")}>
                          <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>Provider</dt><dd>{expectedExchangeRate ? floatDecimals((parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9)*0.005 / 10**9, 4) : <LoaderDotsText/>} EVER</dd>
                          </FlexContainer>
                          <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>Execution</dt><dd>~ {expectedExchangeRate ? 2.5 : <LoaderDotsText/>} EVER</dd>
                          </FlexContainer>
                          {/* <FlexContainer
                            className={cntr("card-summary-row")}
                            justify="space-between"
                          >
                            <dt className={cntr("color-faded", "paragraph")}>Execution</dt><dd>{expectedExchangeRate ? expectedExchangeRate.expected_fee / 10**9 : <LoaderDotsText/>} EVER</dd>
                          </FlexContainer> */}
                        </dl>

                      </Flex>
                    </FlexContainer>
                  </Panel>
                </Flex>
                <Flex>
                  <SlideWrapper direction={"next"}>  
                    <Button
                      className={cntr("button-cta")}
                      size="large"
                      variant="button"
                      color="primary"
                      // iconLeft={{
                      //   animation: "none",
                      //   icon: <Icon icon="arrow-left"/>
                      // }}
                      disabled={expectedExchangeRate && (parseInt(expectedExchangeRate.expected_amount) - 2.5*10**9 < 0)}
                      onClick={(e) => {
                        onSubmit(client)
                      }}
                    >
                      Proceed to transfer
                    </Button>
                  </SlideWrapper>
                </Flex>
              </FlexContainer>
            </Panel>
          </SwiperSlide>
          <SwiperSlide>
          <Panel
            header={<h5 className={"title title-normal"}>By bank card</h5>}
            className={cntr("panel")}
          >
            
            <Input<FormValues>
              title={""}
              placeholder={"Card number"}
              composition={"substitute"}

              name={"card_number"}
              type={"number"}
              
              validation={formik.errors["card_number"]}
              
              value={formik.values.card_number}
             
              onChange={formik.handleChange}
              mask={"xxxx  xxxx  xxxx  xxxx"}

              className={cnb("cards-select")}
              color="default"
            />

            <FlexContainer
              direction="row"
              justify="stretch"
              align="center"
            >
              <Flex
                grow={1}
                className={cntr("card--card_date")}
              >
                <Input<FormValues>
                  title={""}
                  placeholder={"MM / YY"}
                  composition={"substitute"}
                  mask={"xx  /  xx"}
                  name={"card_date"}
                  type={"number"}
                  
                  validation={formik.errors["card_date"]}
                  
                  value={formik.values.card_date}
                
                  onChange={formik.handleChange}

                  color="default"
                />
              </Flex>
              <Flex
                grow={1}
                className={cntr("card--card")}
              >
                <Input<FormValues>
                  title={""}
                  placeholder={"CVC/CVV"}
                  composition={"substitute"}

                  name={"card"}
                  type={"password"}
                  mask="xxx"
                  
                  validation={formik.errors["card"]}
                  
                  value={formik.values.card}
                
                  onChange={formik.handleChange}


                  color="default"
                />
              </Flex>
            </FlexContainer>

            
            <SlideWrapper direction={!formik.values.amountPay || !formik.values.amountGet || Boolean(formik.errors.amountPay) || Boolean(formik.errors.amountGet) ? undefined : "next"}>  
              <Button
                className={""}
                size="large"
                variant="button"
                color="primary"
                disabled={!formik.values.amountPay || Boolean(formik.errors.amountGet)}
                onClick={() => {}}  
              >
                Get EVER
              </Button>
            </SlideWrapper>
          </Panel>

          </SwiperSlide>
          <SwiperSlide>
            <Panel
              className={cntr("panel-with-button", "panel")}
              header={null}
              style={{
                position: "relative"
              }}
            >
              
              <FlexContainer
                  justify="center"
                  direction="column"
                  align="center"
                  style={{width: "100%", minHeight: "300px"}}
                >
                  <Flex
                    className={cnb("status-loader", "status-loader-inprogress")}
                  >
                    <Loader/>
                  </Flex>
                  <Flex>
                    In progress...
                  </Flex>
                </FlexContainer>
            </Panel>
          </SwiperSlide>
        </Swiper>
      </form>
    </FormikProvider>
  );
};

export default FlexBuyForm;