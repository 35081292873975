import { useWidgetParams } from '../../../controllers/useWidgetParams';
import { TransferForm } from "../../../layouts/forms/flex/FlexTransferForm";
import { WidgetColors } from '../../../types';
import { MetaDecorator } from "../../../components";

const Swap = () => {
  let widgetColors: WidgetColors = useWidgetParams();

  return (
    <>
      <MetaDecorator
        title="Lighting fast swaps · Payments Surf"
        description="Swap tokens with super low fees at Everscale DEX."
        keywords="ever, surf, payments, everscale, swap, trade, dex, exchange, wever, usdt, usdc, btc, eth, bridge, qube"
        url="swap"
        image="swap"
      />
      <TransferForm widgetColors={widgetColors} />
    </>
  );
};

export default Swap;
