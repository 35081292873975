import React, { useEffect, useState, useRef, useCallback, ReactNode } from "react";
import { useFormik, Field, FormikProvider } from 'formik';
//import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { isMobile } from "react-device-detect";
import * as Yup from 'yup';


import classnames from "classnames/bind";
import QRCodeStyling from "qr-code-styling";

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/scss'; // core Swiper
import 'swiper/scss/pagination'; // Pagination module

import {v4 as uuidv4} from 'uuid';

import { useCookies } from "react-cookie";
import { Panel, Input, FlexContainer, Flex, Icon, ModalSheet } from "../../components";
import { Button } from "../../components/button_";
import { floatDecimals } from "../../utils";
import styles from './Form.module.scss';
import stylesBuy from './BuyForm.module.scss';
import { InputComponent } from "../../components";

const cnb = classnames.bind(styles);
const cnbuy = classnames.bind(stylesBuy);

const __ENV = process.env.ENV ? process.env.ENV : 'prod';

const EVER_RATE:{[key: string]: number;} = {
  'USD': 0.4,
  'EUR': 0.5
}

const API_URL:{[key: string]: string} = {
  dev: 'http://localhost:3333',
  prod: 'https://buy.ever.surf/api'
}

const ENDPOINTS = {
  accessToken: '/accessToken'
};


interface FormValues {
  majorAmount: string;
  major: string;
  minorAmount: string;
  minor: string;
  wallet: string;
  everscale_addr: string;
  email: string;
  card: string;
  card_date: string;
  card_card: string;
  card_holder: string;
  
}

const Loader = () => (
  <svg className={styles.spinner}>
    <circle cx="20" cy="20" r="12"></circle>
  </svg>
);

const SlideWrapper = ({direction, children}: {direction: "next" | "prev", children: ReactNode}) => {
  const swiper = useSwiper();
  return (
    <div className={cnb("swiper-button-wrapper")} onClick={() => direction === "prev" ? swiper.slidePrev() : swiper.slideNext()}>{children}</div>
  );
}

export const BuyForm = () => {
  //const [cookies, setCookie, removeCookie] = useCookies(['sessionId', 'sumsubSessionToken']);
  // const [accessToken, setAccessToken] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>('');
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const refQr = useRef<HTMLDivElement>(null);
  const [invertRate, setInvertRate] = useState<boolean>(false);

  const qrCode = new QRCodeStyling({
    width: 350,
    height: 350,
    type: "svg",
    margin: 0,
    qrOptions: {
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "L"
    },
    imageOptions: {
        hideBackgroundDots: false,
        imageSize: 0,
        margin: 0
    },
    dotsOptions: {
        type: "rounded",
        color: "#000000"
    },
    backgroundOptions: {
        color: "transparent"
    },
    image: "",
    cornersSquareOptions: {
        type: undefined,
        color: "#000000"
    },
    cornersDotOptions: {
        type: undefined,
        color: "#000000"
    }
  });

  useEffect(() => {
    // if (authMe.deeplink) {
    //   qrCode.update({
    //     data: authMe.deeplink
    //   });
    //   if (refQr.current) {
    //     refQr.current.innerHTML = '';
    //     qrCode.append(refQr.current);
    //   }
    // }
    // return () => {}
  }, [])

  const currencyOptions = [
    { label: "EVER", title: "Everscale", value: "EVER" },
    { label: "WEVER", title: "Wrapped Everscale", value: "WEVER" },
    { label: "USDT", title: "Teether USD", value: "USDT" },
    { label: "WBTC", title: "Wrapper Bitcoin", value: "WBTC" },
    { label: "SNX", title: "Synthetix Network Token", value: "SNX" },
    { label: "AAVE", title: "Aave", value: "AAVE" },
    { label: "ENJ", title: "Enjin Coin", value: "ENJ" },
    { label: "MANA", title: "Decentraland", value: "MANA" },
    { label: "ANT", title: "Aragon", value: "ANT" },
    { label: "WCUS", title: "Wrapped Celo Dollar", value: "WCUS" },
  ]

  const tokensOptions = [
    { label: "EVER", title: "Ever", value: "EVER" },
  ]

  const validationSchema = Yup.object().shape({
    majorAmount: Yup.number()
      .typeError('Nice try😄')
      .required('')
      .positive('Nice try😄')
      .min(0, 'Minimum 0'),
    minorAmount: Yup.number()
      .typeError('Nice try😄')
      .required('')
      .positive('Nice try😄')
      .min(0, 'Minimum 0'),
    card_card: Yup.string()
      .typeError('Nice try😄')
      .required('')
      .length(3, "3 digits")
      .matches(/^[0-9][0-9][0-9]$/, "3 digits"),

    card_date: Yup.string()
      .typeError('Nice try😄')
      .required('')
      .matches(/^[0-9]{4}$/, "Enter a credit card number"),

    card: Yup.string()
      .typeError('Nice try😄')
      .required('')
      .length(16, "")
      .matches(/^[0-9]{16}$/, "Enter a credit card number"),

    card_holder: Yup.string()
      .typeError('Nice try😄')
      .required('')
      .matches(/^\s*([a-zA-Z]+\s*)+$/, "Enter a holder name"),

    major: Yup.string()
      .typeError('Nice try😄')
      .required(''),
    minor: Yup.string()
      .typeError('Nice try😄')
      .required(''),
    email: Yup.string()
      .email("That's not an email")
      .typeError('Nice try😄')
      .required(''),
    wallet: Yup.string()
      .matches(/^0:[a-f0-9]{64,64}$/, "Doesn't look like a valid Surf wallet ID")
  });
  const INITIAL_AMOUNT = 50;
  const INITIAL_CURRENCY = "EUR";
  const formik = useFormik<FormValues>({
    initialValues: {
      majorAmount: String(INITIAL_AMOUNT),
      major: INITIAL_CURRENCY,
      minorAmount: String(INITIAL_AMOUNT/EVER_RATE[INITIAL_CURRENCY]),
      minor: "EVER",
      wallet: '',
      everscale_addr: '',
      email: '',
      card: '',
      card_date: '',
      card_card: '',
      card_holder: '',
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: ({major}, { setSubmitting }) => {
      if (parseFloat(formik.values.majorAmount) > 600) {
        
        // if (accessToken) {
        //   console.log("accessToken si here and it's " + accessToken);
        //   setSubmitting(false);
        //   // setSumsubActive(true);
        // } else {
        //   console.log("Retrieving accessToken");
        //   setSubmitting(true);
        //   try {
        //     // getToken(sessionId).then((value: any) => {
        //     //   setCookie("sumsubSessionToken", value);
        //     //   setAccessToken(value);
        //     //   setSumsubActive(true);

        //     // });
        //   } catch (error) {
        //     console.error('Error while getting a token: ' + error);
        //   }
        // }
      } else if (sessionId) {
        // console.log("accessToken si here and it's " + accessToken);
        //setSubmitting(false);
        //setSumsubActive(true);
        //launchWebSdk(accessToken);
        // setCheckoutActive(true);
      } 
    }
  });

  useEffect(() => {
    if (refQr.current) {
      qrCode.update({
        data: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
      });
      refQr.current.innerHTML = '';
      qrCode.append(refQr.current);
      refQr.current.querySelector("svg")!.setAttribute("viewBox", "0 0 350 350");
    }
  }, [confirmed]);

  useEffect(() => {
    if (formik.values.majorAmount && (parseFloat(formik.values.minorAmount) !== parseFloat(formik.values.majorAmount)/(EVER_RATE[formik.values.major]))) {
      formik.setFieldValue("minorAmount", floatDecimals(parseFloat(formik.values.majorAmount)/(EVER_RATE[formik.values.major] || 1), 3));
    }
    }, [formik.values.majorAmount, formik.values.major]);
  
  useEffect(() => {
    if (formik.values.minorAmount && (parseFloat(formik.values.majorAmount) !== parseFloat(formik.values.minorAmount)*(EVER_RATE[formik.values.major]))) {
      formik.setFieldValue("majorAmount", floatDecimals(parseFloat(formik.values.minorAmount)*(EVER_RATE[formik.values.major] || 1), 3));
    }
  }, [formik.values.minorAmount, formik.values.minor]);

  return (
    <div
      className={cnb("widget", "buy-widget")}
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={cnb({ "active": false }, "form", cnbuy("form"))}>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          allowTouchMove={false}
          navigation={false}
          pagination={false}
          scrollbar={false}
          onSwiper={(swiper) => {}}
          onSlideChange={(swiper) => {
            setActiveSlide(swiper.activeIndex)
            console.log(swiper)
          }}
        >
          <SwiperSlide>
            <Panel
              header={<h5 className={"title title-normal"}>Buy tokens</h5>}
            >
              <Input<FormValues>
                type="number"
                tabIndex={activeSlide === 0 ? 1000 : -1}
                disabled={false}
                className={cnbuy("input")}
                units={"EUR"}
                label={"You pay"}
                placeholder={"0"}
                composition={"duplet"}
                name={"majorAmount"}
                caption={"min. 10 — 10,000 max."}
              />
              <Input<FormValues>
                tabIndex={activeSlide === 0 ? 1002 : -1}
                disabled={false}
                className={cnbuy("input")}
                units={"EVER"}
                label={"You get"}
                placeholder={"0"}
                composition={"duplet"}
                name={"minorAmount"}
              />

              <div
                className={cnb("rate", cnbuy("rate"), "color-white", "action", "action-small")}
                onClick={() => setInvertRate(!invertRate)}
              >
                1&nbsp;&nbsp;{invertRate ? formik?.values.major :  formik?.values.minor} &nbsp;&nbsp;=&nbsp;&nbsp; {invertRate ? 1 / EVER_RATE[formik.values.major] : EVER_RATE[formik.values.major]}&nbsp;&nbsp;{invertRate ? formik?.values.minor :  formik?.values.major}
              </div>

              <SlideWrapper direction="next"><Button
                tabIndex={activeSlide === 0 ? 1003 : -1}
                size="lg" 
                variant="primary"
                type="button"
                className={cnb("button-action")}
                disabled={formik.isSubmitting}
                
              >
              {formik.isSubmitting && <Loader/>} Continue
              </Button></SlideWrapper>
            </Panel>
          </SwiperSlide>
          <SwiperSlide>
            <Panel
              className={cnb("slider-slide-panel")}
              header={<h5 className={"title title-normal"}>
                <SlideWrapper direction="prev">
                  <Icon icon="arrow-left" className={cnb("icon-back")}/>Back
                </SlideWrapper>
              </h5>}
            >
              <SlideWrapper direction="next"><Button 
                tabIndex={activeSlide === 1 ? 1 : -1}
                size="lg" 
                variant="default"
                type="button"
                className={cnb("button-action", cnbuy("button"))}
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting && <Loader/>} Continue as a Guest
              </Button></SlideWrapper>
                
              <Button 
                tabIndex={activeSlide === 1 ? 2 : -1}
                size="lg" 
                variant="primary"
                type="button"
                className={cnb("button-action", cnbuy("button"))}
                disabled={formik.isSubmitting}
                onClick={() => setConfirmed(true)}
              >
                {formik.isSubmitting && <Loader/>} Sign in with Surf
              </Button>

              <a href="https://ever.surf/download"><div className={cnb("rate", cnbuy("rate"), "align-center", "align-center", "color-white", "action", "action-small")}>
                Set up a wallet to store tokens
              </div></a>
              <ModalSheet
                show={confirmed}
                className={cnb("swap-confirmed")}
                header={<FlexContainer
                  justify="space-between"
                  align="center"
                >
                  <Flex className={"title title-normal"}>Confirm to proceed</Flex>
                  <Flex>
                    <Button
                      size="blank"
                      variant="transparent"
                      onClick={() => setConfirmed(false)}
                    >
                      <Icon className={styles['']} icon={"close"} />
                    </Button>
                  </Flex>
                </FlexContainer>}
              >
                <div ref={refQr} className={cnb("qrcode", cnbuy("qrcode"))}></div>
                <div className={cnb("qrcode-desc", "paragraph", "paragraph-small", "align-center")}>{`Scan this QR code, follow the link\n and confirm the swap in the app`}</div>
                <a href={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"} className={cnb("paragraph", "paragraph-medium", "align-center")}>Open Everlink</a>
              </ModalSheet>
            </Panel>
          </SwiperSlide>
          <SwiperSlide>
            <Panel
              className={cnb("slider-slide-panel")}
              header={<h5 className={"title title-normal"}>
                <SlideWrapper direction="prev">
                  <Icon icon="arrow-left" className={cnb("icon-back")}/>Back
                </SlideWrapper>
              </h5>}
            >
              <Input<FormValues>
                disabled={false}
                className={cnbuy("input")}
                action={isMobile ? <Icon icon="qrcode"/> : undefined}
                placeholder={"Everscale address"}
                composition={"substitute"}
                name={"wallet"}
              />
              <Input<FormValues>
                disabled={false}
                className={cnbuy("input")}
                placeholder={"Email"}
                composition={"substitute"}
                name={"email"}
              />
              <SlideWrapper direction="next"><Button
                size="lg" 
                variant="primary"
                type="button"
                className={cnb("button-action", cnbuy("button"))}
                disabled={!Boolean(formik.values.wallet && !formik.errors.wallet) || !Boolean(formik.values.email && !formik.errors.email)}
              >
                Continue
              </Button></SlideWrapper>

            </Panel>
          </SwiperSlide>
          <SwiperSlide>
            <Panel
              className={cnb("slider-slide-panel")}
              header={<h5 className={"title title-normal"}>
                <SlideWrapper direction="prev">
                  <Icon icon="arrow-left" className={cnb("icon-back")}/>Back
                </SlideWrapper>
              </h5>}
            >
              <dl className={cnb("swap-summary", "paragraph", "paragraph-normal", cnbuy("swap-summary"))}>
                <FlexContainer
                  className={cnb("swap-summary-row", cnbuy("swap-summary-row"))}
                  justify="space-between"
                >
                  <dt className={cnb("color-faded", "paragraph")}>Pay</dt><dd>{formik.values.majorAmount}&nbsp;{formik.values.major}</dd>
                </FlexContainer>
                <FlexContainer
                  className={cnb("swap-summary-row", cnbuy("swap-summary-row"))}
                  justify="space-between"
                >
                  <dt className={cnb("color-faded", "paragraph")}>Get</dt><dd>{formik.values.minorAmount}&nbsp;{formik.values.minor}</dd>
                </FlexContainer>
                <dt className={cnb("color-faded", "paragraph", "paragraph-small")}>Deposit address</dt>
                <dd>{formik.values.wallet}</dd>
              </dl>
                
              <SlideWrapper direction="next"><Button 
                size="lg" 
                variant="primary"
                type="button"
                className={cnb("button-action")}
                disabled={formik.isSubmitting}
              >
                Continue
              </Button></SlideWrapper>
            </Panel>
          </SwiperSlide>
          <SwiperSlide>
            <Panel
              className={cnb("slider-slide-panel")}
              header={<h5 className={"title title-normal"}>
                <SlideWrapper direction="prev">
                  <Icon icon="arrow-left" className={cnb("icon-back")}/>Back
                </SlideWrapper>
              </h5>}
            >
              <Input<FormValues>
                disabled={false}
                mask={"xxxx  xxxx  xxxx  xxxx"}
                className={cnbuy("input", "input-card-number")}
                label={""}
                placeholder={"Card number"}
                composition={"substitute"}
                name={"card"}
              />
              <FlexContainer
                className={cnb("", cnbuy("card-date-row"))}
                justify="space-between"
              >
                <Input<FormValues>
                  disabled={false}
                  mask={"xx  /  xx"}
                  className={cnbuy("input")}
                  label={""}
                  placeholder={"MM / YY"}
                  composition={"substitute"}
                  name={"card_date"}
                />

                <Field
                  name={"card_date"}
                  as={InputComponent}
                  label={""}
                  // caption={"Type carefully"}
                  placeholder={"CVC/CVV"}
                  composition={"substitute"}
                  hidden={true}
                  type={"hidden"}
                  title={""}
                  validation={formik.errors["card_date"]}
                  mask="xxx"
                  // value={value}
                  // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setValue(e.target.value)}}
                />
                <Input<FormValues>
                  disabled={false}
                  className={cnbuy("input")}
                  label={""}
                  placeholder={"CVC/CVV"}
                  composition={"substitute"}
                  name={"card_card"}
                />
              </FlexContainer>
              <Input<FormValues>
                disabled={false}
                className={cnbuy("input")}
                placeholder={"Cardholder name"}
                composition={"substitute"}
                name={"card_holder"}
              />

              <SlideWrapper direction="next"><Button
                size="lg" 
                variant="primary"
                type="button"
                className={cnb("button-action", cnbuy("button"))}
                disabled={
                  !Boolean(formik.values.card && !formik.errors.card) || 
                  !Boolean(formik.values.card_card && !formik.errors.card_card) || 
                  !Boolean(formik.values.card_date && !formik.errors.card_date) || 
                  !Boolean(formik.values.card_holder && !formik.errors.card_holder)}
              >
                Continue
              </Button></SlideWrapper>

            </Panel>
          </SwiperSlide>
        </Swiper>
        </form>
      </FormikProvider>
    </div>
  );
};

export default BuyForm;