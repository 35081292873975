import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../types";

import {
  setEverAddress,
  setEverType
} from "../redux/actions";

import {
  address,
 } from "../interfaces";

export interface IUseConnect {
  address: address,
  type: string,
  setConnect: (props: any) => void 
}

export const useConnect = (): IUseConnect => {
  const dispatch = useDispatch();

  const { 
    address: {data: address},
    type: {data: type},
  } = useSelector((state: RootState) => state.ever);


  const setConnect = ({address: addressProp, type: typeProp}: {address: address, type: string}) => {
    if (addressProp !== undefined) dispatch(setEverAddress(addressProp));
    if (typeProp !== undefined) dispatch(setEverType(typeProp));
  }

  return useMemo(
    () => ({
      address: address,
      type: type,
      setConnect
    }),
    [address, type, setConnect],
  );
};
